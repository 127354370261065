@import url("https://fonts.googleapis.com/css?family=Lato:300,300i,400,400i,700,700i,900");


html,
body {
  font-size: 16px;
  color: #ffffffc9;
  font-family: "Lato", sans-serif;
  vertical-align: baseline;
  line-height: 26px;
  font-weight: 400;
  overflow-x:hidden;
  background-color: #000014;
  background-color: #fff;
}



.react-responsive-modal-overlay {
  background: rgb(16 16 16 / 34%) !important;
}



/*-- Preloader css start --*/
#preloader {
	    background-color: #1A1A1A;
	bottom: 0;
	left: 0;
	position: fixed;
	right: 0;
	top: 0;
	z-index: 2147483647;
	width: 100%;
	height: 100%;
}
#status {
    background-position: center center;
    background-repeat: no-repeat;
    height: auto;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    -webkit-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    -moz-transform: translate(-50%,-50%);
    -o-transform: translate(-50%,-50%);
}


.py-5{
  padding: 100px 0;
}
/*Responsive Media*/
/* ....................................
1.1 Reset CSS
.......................................*/
a {
  transition: all 0.5s;
  text-decoration: none;
}
a:hover {
  text-decoration: none;
  color: #d94910;
}
a:active {
  outline: 0 none;
  text-decoration: none;
}
a:focus {
  outline: 0px solid;
  text-decoration: none;
}

p {
  margin: 0 0 26px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 26px;
  font-weight: 600;
}

img {
  max-width: 100%;
  height: auto;
}

ul {
  list-style: outside none none;
  margin: 0;
  padding: 0;
}

input {
  box-shadow: none;
  border-radius: 0;
  outline: none;
}
input:focus {
  outline: none;
  box-shadow: none;
}
input:active {
  outline: none;
  box-shadow: none;
}

textarea {
  outline: none;
  box-shadow: none;
  border-radius: 0;
}
textarea:focus {
  outline: none;
  box-shadow: none;
}
textarea:active {
  outline: none;
  box-shadow: none;
}

button {
  outline: none;
  box-shadow: none;
  border: none;
  cursor: pointer;
  transition: all 0.5s;
}
button:focus {
  outline: none;
  box-shadow: none;
  border: none;
}
button:active {
  outline: none;
  box-shadow: none;
  border: none;
}

.fix {
  overflow: hidden;
}

.clear {
  clear: both;
}

.d-inline-block {
  display: inline-block;
}

.d-block {
  display: block;
}

.d-table {
  display: table;
}

.w-50 {
  width: 50%;
}

::-moz-selection {
  background: #00c3ff;
  text-shadow: none;
}

::selection {
  background: #00c3ff;
  text-shadow: none;
}

.browserupgrade {
  margin: 26px  0;
  background: #00c3ff;
  color: #333333;
  padding: 26px 0;
}

/***Typography***/
h1 {
  font-size: 36px;
}

h2 {
  font-size: 30px;
}

h3 {
  font-size: 24px;
}

h4 {
  font-size: 18px;
}

.f-16 {
  font-size: 16px;
}

.f-18 {
  font-size: 18px;
}

.f-20 {
  font-size: 20px;
}

.f-22 {
  font-size: 22px;
}

.f-24 {
  font-size: 24px;
}

.f-26 {
  font-size: 26px;
}

.f-28 {
  font-size: 28px;
}

.f-30 {
  font-size: 30px;
}

.f-36 {
  font-size: 36px;
}

.f-40 {
  font-size: 40px;
}

.f-48 {
  font-size: 48px;
}

.f-60 {
  font-size: 60px;
}

.f-72 {
  font-size: 72px;
}

.fw-300 {
  font-weight: 300 !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

.fw-700 {
  font-weight: 700 !important;
}

.fw-800 {
  font-weight: 800 !important;
}

.fw-900 {
  font-weight: 900 !important;
}

.t-u {
  text-transform: uppercase;
}

.t-c {
  text-transform: capitalize;
}

.t-l {
  text-transform: lowercase;
}

/*** Section ***/
.section {
  padding: 80px 0;
}
@media only screen and (max-width: 768px) {
  .section {
    padding: 75px 0;
  }
}

.section-heading {
  display: block;
  position: relative;
  margin: 0 0 60px;
  display: block;
  text-align: center;
}
@media only screen and (max-width: 768px) {
  .section-heading {
    margin: 0 0 50px;
  }
}
@media only screen and (max-width: 480px) {
  .section-heading {
    margin: 0 0 40px;
  }
}
.section-heading h2 {
  font-size: 40px;
  font-weight: 400;
  color: #ffffff;
  margin: 0;
}
.section-heading > p {
  margin: 10px auto;
}
@media only screen and (max-width: 768px) {
  .section-heading h2 {
    font-size: 36px;
  }
}
@media only screen and (max-width: 480px) {
  .section-heading h2 {
    font-size: 30px;
  }
}

.section-heading2 {
  display: block;
  margin: 0 0 60px;
  text-align: center;
}
@media only screen and (max-width: 768px) {
  .section-heading2 {
    margin: 0 0 50px;
  }
}
@media only screen and (max-width: 480px) {
  .section-heading2 {
    margin: 0 0 40px;
  }
}
.section-heading2 h2 {
  font-size: 40px;
  font-weight: 400;
  color: #fff;
}
@media only screen and (max-width: 768px) {
  .section-heading2 h2 {
    font-size: 36px;
  }
}
@media only screen and (max-width: 480px) {
  .section-heading2 h2 {
    font-size: 30px;
  }
}



.logo-area img {
    width: auto;
    height: 64px;
    object-fit: scale-down;
}

@media only screen and (min-width: 1200px) {

  .logo-area img {
    min-width: fit-content;
  }


  header .menu-area {
    text-align: right;
    margin: 7px 0;
    min-width: max-content;
}



}



/***Button***/
.btn1 {
  display: inline-block;
  line-height: 50px;
  height: 50px;
  border-radius: 50px;
  background: rgba(172, 118, 34);
  /* background: -webkit-gradient(left top, right top, color-stop(0%, rgba(172, 118, 34)), color-stop(100%, rgba(229, 175, 65, 1))); */
  background: linear-gradient(to right, #f25a1d 0%, #f25a1d 100%);
  padding: 0 35px;
  font-weight: 700;
  color: #fff;
  text-transform: uppercase;
  -webkit-animation-delay: 2.4s;
  animation-delay: 2.4s;
}

.login-btn .btn1 {
  font-size: 13px;
  height: auto !important;
  line-height: unset;
  padding: 6px 23px;
  letter-spacing: 0.5px;
}



@media only screen and (max-width: 480px) {
  .btn1 {
    padding: 0 20px;
    height: 40px;
    line-height: 40px;
    font-size: 13px;
  }
  


}
.btn1 i {
  padding-right: 8px;
}
.btn1:hover {
  background: rgb(182 57 30);
  background: -webkit-gradient(left top, right top, color-stop(0%, rgba(229, 175, 65, 1)), color-stop(100%, rgba(172, 118, 34)));
  background: linear-gradient(to right, rgb(182 57 30) 0%, rgb(182 57 30) 100%);
  color: #fff;
}
.btn2 {
  display: inline-block;
  line-height: 50px;
  height: 50px;
  border-radius: 50px;
  background: rgba(172, 118, 34);
background: -moz-linear-gradient(left, rgba(172, 118, 34) 0%, rgba(229, 175, 65, 1) 100%);
background: -webkit-gradient(left top, right top, color-stop(0%, rgba(172, 118, 34)), color-stop(100%, rgba(229, 175, 65, 1)));
background: -webkit-linear-gradient(left, rgba(172, 118, 34) 0%, rgba(229, 175, 65, 1) 100%);
background: -o-linear-gradient(left, rgba(172, 118, 34) 0%, rgba(229, 175, 65, 1) 100%);
background: -ms-linear-gradient(left, rgba(172, 118, 34) 0%, rgba(229, 175, 65, 1) 100%);
background: linear-gradient(to right, #f25a1d 0%, #f25a1d 100%);
  padding: 0 35px;
  font-weight: 700;
  color: #fff;
  text-transform: uppercase;
  animation-delay: 2.4s
}
@media only screen and (max-width: 480px) {
  .btn2 {
    padding: 0 20px;
    height: 40px;
    line-height: 36px;
    font-size: 13px;
    border: 2px solid rgba(172, 118, 34);
  }
}
.btn2:hover {
  background: rgba(172, 118, 34);
  color: #fff;
}

.btn3 {
  display: inline-block;
  line-height: 50px;
  height: 50px;
  border-radius: 50px;
  background: rgba(172, 118, 34);
background: -moz-linear-gradient(left, rgba(172, 118, 34) 0%, rgba(229, 175, 65, 1) 100%);
background: -webkit-gradient(left top, right top, color-stop(0%, rgba(172, 118, 34)), color-stop(100%, rgba(229, 175, 65, 1)));
background: -webkit-linear-gradient(left, rgba(172, 118, 34) 0%, rgba(229, 175, 65, 1) 100%);
background: -o-linear-gradient(left, rgba(172, 118, 34) 0%, rgba(229, 175, 65, 1) 100%);
background: -ms-linear-gradient(left, rgba(172, 118, 34) 0%, rgba(229, 175, 65, 1) 100%);
background: linear-gradient(to right, #f25a1d 0%, #f25a1d 100%);
  padding: 0 20px;
  font-weight: 700;
  color: #fff;
  text-transform: uppercase;
}
@media only screen and (max-width: 480px) {
  .btn3 {
    padding: 0 20px;
    height: 40px;
    line-height: 36px;
    font-size: 13px;
    border: 2px solid #3d3f69;
  }
}
.btn3:hover {
background: rgba(229, 175, 65, 1);
background: -moz-linear-gradient(left, rgba(229, 175, 65, 1) 0%, rgba(172, 118, 34) 100%);
background: -webkit-gradient(left top, right top, color-stop(0%, rgba(229, 175, 65, 1)), color-stop(100%, rgba(172, 118, 34)));
background: -webkit-linear-gradient(left, rgba(229, 175, 65, 1) 0%, rgba(172, 118, 34) 100%);
background: -o-linear-gradient(left, rgba(229, 175, 65, 1) 0%, rgba(172, 118, 34) 100%);
background: -ms-linear-gradient(left, rgba(229, 175, 65, 1) 0%, rgba(172, 118, 34) 100%);
background: linear-gradient(to right, rgba(229, 175, 65, 1) 0%, rgba(172, 118, 34) 100%);
  color: #fff;

}

/*** Reset ***/
.p-re {
  position: relative;
}

.p-ab {
  position: absolute;
}

.list-inline li {
  display: inline-block;
}

.af-be:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.af-be:before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.af:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.be:before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.bg-img {
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center center !important;
}

.floatleft {
  float: left;
}

.floatright {
  float: right;
}

.alignleft {
  float: left;
  margin-right: 15px;
  margin-bottom: 26px;
}
.ss_menu_item.border-top {
  border-top: 1px solid #ffffff4a;
}
.alignright {
  float: right;
  margin-left: 15px;
  margin-bottom: 26px;
}

.aligncenter {
  display: block;
  margin: 0 auto 26px;
}

.dbox {
  overflow: hidden;
  z-index: 1;
  vertical-align: middle;
  width: 100%;
}
.dbox .dleft {
  display: inline-block;
  padding-right: 10px;
}
.dbox .dleft > * {
  border-radius: 100%;
}
.dbox .dright {
  display: table;
  padding-left: 0;
  /* text-align: center; */
  width: 100%;
  /* margin-top: 1.5rem; */
}
.carousel-nevigation{
	position:absolute;
	top:50%;
	float:left;
	width:100%;
}
.carousel-nevigation > .prev{
	left:-138px;
	position:absolute;
	width:50px;
	height:50px;
	line-height:50px;
	padding-top: 9px;
    padding-left: 3px;
	border-radius:100%;
	background:#1a1a1a;
	    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.carousel-nevigation > .next{
	right:-138px;
	position:absolute;
	width:50px;
	height:50px;
	padding-top: 9px;
    padding-left: 12px;
	border-radius:100%;
	background:#1a1a1a;
	    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.slider-area:hover .carousel-nevigation > .prev{
	left:10px;
	    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.slider-area:hover .carousel-nevigation > .next{
	right:10px;
	    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.carousel-nevigation > .next:hover, .carousel-nevigation > .prev:hover{
	background: rgba(172, 118, 34);
background: -moz-linear-gradient(left, rgba(172, 118, 34) 0%, rgba(229, 175, 65, 1) 100%);
background: -webkit-gradient(left top, right top, color-stop(0%, rgba(172, 118, 34)), color-stop(100%, rgba(229, 175, 65, 1)));
background: -webkit-linear-gradient(left, rgba(172, 118, 34) 0%, rgba(229, 175, 65, 1) 100%);
background: -o-linear-gradient(left, rgba(172, 118, 34) 0%, rgba(229, 175, 65, 1) 100%);
background: -ms-linear-gradient(left, rgba(172, 118, 34) 0%, rgba(229, 175, 65, 1) 100%);
background: linear-gradient(to right, #f25a1d 0%, #f25a1d 100%);
	    -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
.carousel-nevigation > .prev i{
	float:left;
	width:35px;
	text-align:right;
	color:#ffffff;
	font-size:16px;
	font-size: 28px;
    padding-right: 10px;
}
.carousel-nevigation > .prev span{
	width:calc(100% - 35px);
	float:left;
	color:#ffffff;
	font-size:16px;
	font-weight:bold;
	line-height: 17px;
}
.carousel-nevigation > .next i{
	float:left;
	width:35px;
	text-align:left;
	color:#ffffff;
	font-size:16px;
	font-size: 28px;
    padding-left: 10px;
}
.carousel-nevigation > .next span{
	width:calc(100% - 35px);
	float:left;
	color:#ffffff;
	font-size:16px;
	font-weight:bold;
	line-height: 17px;
	text-align:right;
}
/***All plugins Reset***/
/*** Margin ***/
.t-0 {
  top: 0;
}

.l-0 {
  left: 0;
}

.r-0 {
  right: 0;
}

.b-0 {
  bottom: 0;
}

.mr-0 {
  margin: 0;
}

.mr-t0 {
  margin-top: 0px !important;
}

.mr-t5 {
  margin-top: 5px !important;
}

.mr-t10 {
  margin-top: 10px !important;
}

.mr-t15 {
  margin-top: 15px !important;
}

.mr-t20 {
  margin-top: 20px !important;
}

.mr-t25 {
  margin-top: 25px !important;
}

.mr-t30 {
  margin-top: 30px !important;
}

.mr-t35 {
  margin-top: 35px !important;
}

.mr-t40 {
  margin-top: 40px !important;
}

.mr-t45 {
  margin-top: 45px !important;
}

.mr-t50 {
  margin-top: 50px !important;
}

.mr-t60 {
  margin-top: 60px !important;
}

.mr-t70 {
  margin-top: 70px;
}

.mr-t80 {
  margin-top: 80px;
}

.mr-t90 {
  margin-top: 90px;
}

.mr-t100 {
  margin-top: 100px;
}

.mr-t110 {
  margin-top: 110px;
}

.mr-t120 {
  margin-top: 120px;
}

.mr-t130 {
  margin-top: 130px;
}

.mr-t140 {
  margin-top: 140px;
}

.mr-t150 {
  margin-top: 150px;
}

.mr-b0 {
  margin-bottom: 0px !important;
}

.mr-b1 {
  margin-bottom: 1px !important;
}
.mr-b5 {
  margin-bottom: 5px;
}

.mr-b10 {
  margin-bottom: 10px;
}

.mr-b15 {
  margin-bottom: 15px;
}

.mr-b20 {
  margin-bottom: 20px;
}

.mr-b25 {
  margin-bottom: 25px;
}

.mr-b30 {
  margin-bottom: 30px;
}

.mr-b35 {
  margin-bottom: 35px;
}

.mr-b40 {
  margin-bottom: 40px;
}

.mr-b45 {
  margin-bottom: 45px;
}

.mr-b50 {
  margin-bottom: 50px;
}

.mr-b60 {
  margin-bottom: 60px;
}

.mr-b70 {
  margin-bottom: 70px;
}

.mr-b80 {
  margin-bottom: 80px;
}

.mr-b90 {
  margin-bottom: 90px;
}

.mr-b100 {
  margin-bottom: 100px;
}

.mr-b110 {
  margin-bottom: 110px;
}

.mr-b120 {
  margin-bottom: 120px;
}

.mr-b130 {
  margin-bottom: 130px;
}

.mr-b140 {
  margin-bottom: 140px;
}

.mr-b150 {
  margin-bottom: 150px;
}

.mr-l0 {
  margin-left: 0px;
}

.mr-l5 {
  margin-left: 5px;
}

.mr-l10 {
  margin-left: 10px;
}

.mr-l15 {
  margin-left: 15px;
}

.mr-l20 {
  margin-left: 20px;
}

.mr-l25 {
  margin-left: 25px;
}

.mr-l30 {
  margin-left: 30px;
}

.mr-l35 {
  margin-left: 35px;
}

.mr-l40 {
  margin-left: 40px;
}

.mr-l45 {
  margin-left: 45px;
}

.mr-l50 {
  margin-left: 50px;
}

.mr-l60 {
  margin-left: 60px;
}

.mr-l70 {
  margin-left: 70px;
}

.mr-l80 {
  margin-left: 80px;
}

.mr-l90 {
  margin-left: 90px;
}

.mr-l100 {
  margin-left: 100px;
}

.mr-l110 {
  margin-left: 110px;
}

.mr-l120 {
  margin-left: 120px;
}

.mr-l130 {
  margin-left: 130px;
}

.mr-l140 {
  margin-left: 140px;
}

.mr-l150 {
  margin-left: 150px;
}

.mr-r0 {
  margin-right: 0px;
}

.mr-r5 {
  margin-right: 5px;
}

.mr-r10 {
  margin-right: 10px;
}

.mr-r15 {
  margin-right: 15px;
}

.mr-r20 {
  margin-right: 20px;
}

.mr-r25 {
  margin-right: 25px;
}

.mr-r30 {
  margin-right: 30px;
}

.mr-r35 {
  margin-right: 35px;
}

.mr-r40 {
  margin-right: 40px;
}

.mr-r45 {
  margin-right: 45px;
}

.mr-r50 {
  margin-right: 50px;
}

.mr-r60 {
  margin-right: 60px;
}

.mr-r70 {
  margin-right: 70px;
}

.mr-r80 {
  margin-right: 80px;
}

.mr-r90 {
  margin-right: 90px;
}

.mr-r100 {
  margin-right: 100px;
}

.mr-r110 {
  margin-right: 110px;
}

.mr-r120 {
  margin-right: 120px;
}

.mr-r130 {
  margin-right: 130px;
}

.mr-r140 {
  margin-right: 140px;
}

.mr-r150 {
  margin-right: 150px;
}

.mr-0 {
  margin: 0;
}

.mr-5 {
  margin: 5px;
}

.mr-10 {
  margin: 10px;
}

.mr-15 {
  margin: 15px;
}

.mr-20 {
  margin: 20px;
}

.mr-25 {
  margin: 25px;
}

.mr-30 {
  margin: 30px;
}

.mr-35 {
  margin: 35px;
}

.mr-40 {
  margin: 40px;
}

.mr-45 {
  margin: 45px;
}

.mr-50 {
  margin: 50px;
}

.mr-60 {
  margin: 60px;
}

.mr-70 {
  margin: 70px;
}

.mr-80 {
  margin: 80px;
}

.mr-90 {
  margin: 90px;
}

.mr-100 {
  margin: 100px;
}

.mr-110 {
  margin: 110px;
}

.mr-120 {
  margin: 120px;
}

.mr-130 {
  margin: 130px;
}

.mr-140 {
  margin: 140px;
}

.mr-150 {
  margin: 150px;
}

/*** Padding ***/
.pd-0 {
  padding: 0 !important;
}

.pd-t0 {
  padding-top: 0px;
}

.pd-t5 {
  padding-top: 5px;
}

.pd-t10 {
  padding-top: 10px;
}

.pd-t15 {
  padding-top: 15px;
}

.pd-t20 {
  padding-top: 20px;
}

.pd-t25 {
  padding-top: 25px;
}

.pd-t30 {
  padding-top: 30px;
}

.pd-t35 {
  padding-top: 35px;
}

.pd-t40 {
  padding-top: 40px;
}

.pd-t45 {
  padding-top: 45px;
}

.pd-t50 {
  padding-top: 50px;
}

.pd-t60 {
  padding-top: 60px;
}

.pd-t70 {
  padding-top: 70px;
}

.pd-t80 {
  padding-top: 80px;
}

.pd-t90 {
  padding-top: 90px;
}

.pd-t100 {
  padding-top: 100px;
}

.pd-t110 {
  padding-top: 110px;
}

.pd-t120 {
  padding-top: 120px;
}

.pd-t130 {
  padding-top: 130px;
}

.pd-t140 {
  padding-top: 140px;
}

.pd-t150 {
  padding-top: 150px;
}

.pd-b0 {
  padding-bottom: 0px;
}

.pd-b5 {
  padding-bottom: 5px;
}

.pd-b10 {
  padding-bottom: 10px;
}

.pd-b15 {
  padding-bottom: 15px;
}

.pd-b20 {
  padding-bottom: 20px;
}

.pd-b25 {
  padding-bottom: 25px;
}

.pd-b30 {
  padding-bottom: 30px;
}

.pd-b35 {
  padding-bottom: 35px;
}

.pd-b40 {
  padding-bottom: 40px;
}

.pd-b45 {
  padding-bottom: 45px;
}

.pd-b50 {
  padding-bottom: 50px;
}

.pd-b60 {
  padding-bottom: 60px;
}

.pd-b70 {
  padding-bottom: 70px;
}

.pd-b80 {
  padding-bottom: 80px;
}

.pd-b90 {
  padding-bottom: 90px;
}

.pd-b100 {
  padding-bottom: 100px;
}

.pd-b110 {
  padding-bottom: 110px;
}

.pd-b120 {
  padding-bottom: 120px;
}

.pd-b130 {
  padding-bottom: 130px;
}

.pd-b140 {
  padding-bottom: 140px;
}

.pd-b150 {
  padding-bottom: 150px;
}

.pd-l0 {
  padding-left: 0px;
}

.pd-l5 {
  padding-left: 5px;
}

.pd-l10 {
  padding-left: 10px;
}

.pd-l15 {
  padding-left: 15px;
}

.pd-l20 {
  padding-left: 20px;
}

.pd-l25 {
  padding-left: 25px;
}

.pd-l30 {
  padding-left: 30px;
}

.pd-l35 {
  padding-left: 35px;
}

.pd-l40 {
  padding-left: 40px;
}

.pd-l45 {
  padding-left: 45px;
}

.pd-l50 {
  padding-left: 50px;
}

.pd-l60 {
  padding-left: 60px;
}

.pd-l70 {
  padding-left: 70px;
}

.pd-l80 {
  padding-left: 80px;
}

.pd-l90 {
  padding-left: 90px;
}

.pd-l100 {
  padding-left: 100px;
}

.pd-l110 {
  padding-left: 110px;
}

.pd-l120 {
  padding-left: 120px;
}

.pd-l130 {
  padding-left: 130px;
}

.pd-l140 {
  padding-left: 140px;
}

.pd-l150 {
  padding-left: 150px;
}

.pd-r0 {
  padding-right: 0px;
}

.pd-r5 {
  padding-right: 5px;
}

.pd-r10 {
  padding-right: 10px;
}

.pd-r15 {
  padding-right: 15px;
}

.pd-r20 {
  padding-right: 20px;
}

.pd-r25 {
  padding-right: 25px;
}

.pd-r30 {
  padding-right: 30px;
}

.pd-r35 {
  padding-right: 35px;
}

.pd-r40 {
  padding-right: 40px;
}

.pd-r45 {
  padding-right: 45px;
}

.pd-r50 {
  padding-right: 50px;
}

.pd-r60 {
  padding-right: 60px;
}

.pd-r70 {
  padding-right: 70px;
}

.pd-r80 {
  padding-right: 80px;
}

.pd-r90 {
  padding-right: 90px;
}

.pd-r100 {
  padding-right: 100px;
}

.pd-r110 {
  padding-right: 110px;
}

.pd-r120 {
  padding-right: 120px;
}

.pd-r130 {
  padding-right: 130px;
}

.pd-r140 {
  padding-right: 140px;
}

.pd-r150 {
  padding-right: 150px;
}

.pd-0 {
  padding: 0px;
}

.pd-5 {
  padding: 5px;
}

.pd-10 {
  padding: 10px;
}

.pd-15 {
  padding: 15px;
}

.pd-20 {
  padding: 20px;
}

.pd-25 {
  padding: 25px;
}

.pd-30 {
  padding: 30px;
}

.pd-35 {
  padding: 35px;
}

.pd-40 {
  padding: 40px;
}

.pd-45 {
  padding: 45px;
}

.pd-50 {
  padding: 50px;
}

.pd-60 {
  padding: 60px;
}

.pd-70 {
  padding: 70px;
}

.pd-80 {
  padding: 80px;
}

.pd-90 {
  padding: 90px;
}

.pd-100 {
  padding: 100px;
}

.pd-110 {
  padding: 110px;
}

.pd-120 {
  padding: 120px;
}

.pd-130 {
  padding: 130px;
}

.pd-140 {
  padding: 140px;
}

.pd-150 {
  padding: 150px;
}

/*....................................
2. Header area start here
....................................*/
header {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  background: #1a1a1a;
  background-color: #ffffff;
  padding-top: 10px;
  padding-bottom: 10px;
  -webkit-backdrop-filter: blur(26px);
  backdrop-filter: blur(26px);
}
header .menu-area {
  text-align: right;
  margin: 7px 0;
}
header .menu-area nav {
  display: inline-block;
}
header .menu-area nav ul li {
  display: inline-block;
  position: relative;
  padding: 0 0;
}
@media only screen and (max-width: 1200px) {
  header .menu-area nav ul li {
    padding: 0 10px;
  }
}


header .menu-area nav ul li:first-child {
  padding-left: 0;
}
header .menu-area nav ul li a {
  display: inline-block;
  color: #000;
  font-size: 14px;
  text-transform: uppercase;
}
header .menu-area .language {
  display: inline-block;
  margin-left: 15px;
  width: 40px;
  position: relative;
}
@media only screen and (max-width: 1200px) {
  header .menu-area .language {
    display: none;
  }
}
header .menu-area .language span {
  display: block;
  cursor: pointer;
}
header .menu-area .language ul {
  width: 40px;
  display: inline-block;
  background: #fff;
  padding: 10px;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  display: none;
}
header .menu-area .language ul li {
  display: block;
  padding: 2px 0;
  cursor: pointer;
}
header .menu-area .language ul li:hover {
  background: #ddd;
}
header .menu-area .login-btn {
  display: inline-block;
  margin: 0 30px 0 5px;
  display: inline-flex;
}

.btn.active, .btn:active {
  background-image: linear-gradient(to right, #f25a1d 0%, #f25a1d 100%);
  /* outline: 0; */
  box-shadow: none;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: .655em;
  vertical-align: middle;
  content: "";
  border-top: .3em solid;
  border-right: .3em solid transparent;
  border-bottom: 0;
  border-left: .3em solid transparent;
}
.dropdown-toggle {
  padding-right: 16px !important;
}







.dropdown-menu {
  left: auto;
  right: 0;
  float: none;
  border: 0;
  padding: 0;
  min-width: 205px;
  top: calc(100% + 26px);
  overflow: hidden;
  /* opacity: 0; */
  /* transition:         all 400ms ease;
  -moz-transition:    all 400ms ease;
  -webkit-transition: all 400ms ease;
  -o-transition:      all 400ms ease;
  -ms-transition:     all 400ms ease; */
}

a.dropdown-item {
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  padding: 9px 15px !important;
  border-bottom: 1px solid #3333;
  color: #333 !important;
  font-size: 14px !important;
  margin: 0 !important;
}

a.dropdown-item:hover {
  color: #fff !important;
  background: #f25a1d !important;
}

a.dropdown-item:last-child{
  border: 0;
}











header .menu-area .side_menu {
  display: inline-block;
  margin-right: 15px;
}
header .menu-area .side_menu span {
  display: inline-block;
  width: 50px;
  height: 50px;
  line-height: 46px;
  border-radius: 100%;
  border: 2px solid #85857d;
  text-align: center;
  cursor: pointer;
}
header .menu-area .side_menu span i {
  color: #fff;
}
header .mobilemenu .mean-container .mean-nav {
  background: #1D1D22;
  margin-top: 10px;
}
@media only screen and (max-width: 480px) {
  header .mobilemenu .mean-container .mean-nav {
    width: 300px;
  }
}
@media only screen and (max-width: 320px) {
  header .mobilemenu .mean-container .mean-nav {
    width: 280px;
  }
}
header .mobilemenu .mean-container .mean-nav ul li a {
  font-size: 13px;
  padding: 0 5%;
  line-height: 44px;
}
header .mobilemenu .mean-container .mean-nav ul li a.mean-expand {
  padding: 0;
  line-height: 44px;
  height: 44px !important;
  padding: 0 12px !important;
}
header .mobilemenu .mean-container .mean-nav ul li a.mean-expand:hover {
  background: #1D1D22;
}
header .mobilemenu .mean-container .mean-nav ul li a.mean-expand.mean-expand.mean-clicked {
  background: #1D1D22;
}
header .mobilemenu .mean-container .mean-nav ul li a:hover {
  color: #1a1a1a;
  background: transparent;
}
header .mobilemenu .mean-container .mean-bar {
  background: inherit;
  padding: 0;
  min-height: 0;
  position: absolute;
  right: 20px;
  top: 10px;
  z-index: 9999;
  width: auto !important;
}
@media only screen and (max-width: 320px) {
  header .mobilemenu .mean-container .mean-bar {
    top: 0;
  }
}
header .mobilemenu .mean-container a.meanmenu-reveal {
  top: 0;
  right: 0 !important;
  background: #e05d5e;
  height: 10px;
  padding: 8px 11px 17px;
  border-radius: 5px;
}
.wd_scroll_wrap{
	float:left;
	width:100%;
}
.menu_fixed {
  position: fixed !important;
  left: 0 !important;
  right: 0 !important;
  top: 0 !important;
  z-index: 1000 !important;
  background: #ffffff;
}
.rp_mobail_menu_main_wrapper{
	float:none;
	width:100%;
}
#sidebar {
  position: fixed;
  display: block;
  height: 100%;
  top: 0px;
  left: -500px;
  background-color: #1a1a1a;
  overflow: scroll;
  overflow-x: hidden;
  z-index:1000;
  height: 100vh;
}

#links {
  position: relative;
  float: left;
}

#link_list {
  list-style-type: none;
  width: 100%;
  padding: 0px 50px 0px 0px;
}

#link_list li {
  display: block;
  width: 100%;

}

#link_list li:hover {
  background-color: #e74c3c;
}

#toggle {
  float: right;
  position: relative;
  bottom: -19px;
  right: 0;
  border-radius: 20px;
  text-align: center;
  cursor: pointer;
}
#toggle_close {
  position: absolute;
  z-index: 100;
  right: 20px;
  top: 20px;
  font-size: 25px;
  color: #000;
  cursor: pointer;
}
#toggle * {
  fill: #000;
}

@import url(https://fonts.googleapis.com/css?family=Raleway:400,200);
#cssmenu,
#cssmenu ul,
#cssmenu ul li,
#cssmenu ul li a {
  margin: 0;
  padding: 0;
  border: 0;
  list-style: none;
  line-height: 1;
  display: block;
  position: relative;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
#cssmenu {
  width: 300px;
  font-family: Raleway, sans-serif;
  color: #ffffff;
}
#cssmenu ul ul {
  display: none;
}

.align-right {
  float: right;
}
#cssmenu ul li a {
  padding: 16px 22px;
  cursor: pointer;
  z-index: 2;
  font-size: 16px;
  text-decoration: none;
  color: #ffffff;
  font-weight:bold;
  border-bottom:1px solid rgba(211, 211, 211, 0.49);
  background: #1a1a1a;
   -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}
#cssmenu ul li:first-child a{
	border-top:1px solid #d3d3d3;
}
#cssmenu ul li:hover a, #cssmenu ul li.active a{
	background:#45487d !important;
	border-bottom:1px solid rgba(211, 211, 211, 0.49);
	color:#ffffff;
	 -webkit-transition: all 0.5s;
    -o-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -moz-transition: all 0.5s;
    transition: all 0.5s;
}

 ul ul > li.has-sub > a:before {
  top: 20px;
  background:#ffffff;
}
.menu_fixed{
	position:fixed !important;
	left:0 !important;
	right:0 !important;
	top:0 !important;
	z-index: 1000;
}
#sidebar h1 a {
  font-size: 20px;
  color: #ffffff !important;
  padding-left: 20px;
  font-weight: bold;
  padding: 4px 0 1px 20px !important;
  display: block;
}
#sidebar h1 a > img {
  max-width: 130px;
}
#sidebar h1 {
  margin: 0;
  color: #ffffff;
  padding-bottom: 4px;
  background: #f5f5f5;
}

.btc_main_menu ul li:hover a, .btc_main_menu ul li.active a{
	color: #f25a1d;
	-webkit-transition: all 0.5s;
	-o-transition: all 0.5s;
	-ms-transition: all 0.5s;
	-moz-transition: all 0.5s;
	transition: all 0.5s;
}
/*....................................
3. Slider area start here
....................................*/
.slider-area {
  display: block;
  position: relative;
  background: #1a1c35;
}


.slide {
  position: relative;
}



.slider-area .carousel {
  position: relative;
  z-index: 9;
  padding: calc(10vh + 84px) 0 18vh;
}
.slider-area .slider-content {
  display: block;
  position: relative;
  padding: 0;
}
@media only screen and (max-width: 992px) {
  .slider-area .slider-content {
    text-align: center;
  }
}
.slider-area .slider-content ul {
  display: block;
  margin: 0 0 30px;
}
.slider_social_icon1{
	animation-delay: 1.1s
}
.slider_social_icon2{
	animation-delay: 1.2s
}
.slider_social_icon3{
	animation-delay: 1.3s
}
.slider_social_icon4{
	animation-delay: 1.4s
}
.slider_social_icon5{
	animation-delay: 1.5s
}
@media only screen and (max-width: 768px) {
  .slider-area .slider-content ul {
    margin: 0 0 25px;
  }
}
.slider-area .slider-content ul li {
  display: inline-block;
  position: relative;
  padding: 0 5px;
}
@media only screen and (max-width: 768px) {
  .slider-area .slider-content ul li {
    padding: 0;
  }
}
.slider-area .slider-content ul li:first-child {
  padding-left: 0;
}
.slider-area .slider-content ul li:last-child {
  padding-right: 0;
}
.slider-area .slider-content ul li a {
  display: block;
  width: 50px;
  height: 50px;
  line-height: 44px;
  border: 3px solid #ffffff45;
  text-align: center;
  color: #ffffff;
  font-size: 20px;
  border-radius: 100%;
  background: #f25a1d29;
}
.slider-area .slider-content ul li a:hover {
  /* color: #f25a1d; */
  /* background: rgba(172, 118, 34); */
  /* background: -webkit-gradient(left top, right top, color-stop(0%, rgba(172, 118, 34)), color-stop(100%, rgba(229, 175, 65, 1))); */
  /* background: linear-gradient(to right, #ffffff 0%, #ffffff 100%); */
  border: 3px solid #ffffff;
}
.slider-area .slider-content h2 {
  font-weight: 900;
  font-size: 50px;
  color: #fff;
  margin: 0 0 0;
  text-shadow: 0 5px 30px rgba(44, 46, 83, 0.3);
  -webkit-animation-delay: 1.8s;
  animation-delay: 1.8s;
  line-height: 1.2;
}
.slider a.previousButton, .slider a.nextButton{
  display: none;
}
.slider-area .slider-content p {
  color: #ffffff;
  font-weight: 400;
  font-size: 19px;
  /* text-align: left; */
  margin: 20px 0 35px;
}
.slider, .slide {
  overflow: unset !important;
}


@media (min-width: 1440px){
  .carousel .container {
      width: 1380px;
  }
}



@media only screen and (max-width: 768px) {
  .slider-area .slider-content h2 {
    margin: 0 0 30px;
  }
}
@media only screen and (max-width: 480px) {
  .slider-area .slider-content h2 {
    font-size: 36px;
  }
}
@media only screen and (max-width: 320px) {
  .slider-area .slider-content h2 {
    font-size: 30px;
  }
}
.slider-area .slider-content .buttons a {
  margin: 0 10px;
}
@media only screen and (max-width: 320px) {
  .slider-area .slider-content .buttons a {
    margin: 0;
  }
}
.slider-area .slider-content .buttons a:first-child {
  margin-left: 0;
}
.slider-area .slider-content .buttons a:last-child {
  margin-right: 0;
  color: #fff;
}
@media only screen and (max-width: 320px) {
  .slider-area .slider-content .buttons a:last-child {
    display: none;
  }
}


.sud svg{
  display: none;
}
.slider-area .slider-content .buttons a:last-child:hover {
background: rgba(229, 175, 65, 1);
background: -moz-linear-gradient(left, rgba(229, 175, 65, 1) 0%, rgba(172, 118, 34) 100%);
background: -webkit-gradient(left top, right top, color-stop(0%, rgba(229, 175, 65, 1)), color-stop(100%, rgba(172, 118, 34)));
background: -webkit-linear-gradient(left, rgba(229, 175, 65, 1) 0%, rgba(172, 118, 34) 100%);
background: -o-linear-gradient(left, rgba(229, 175, 65, 1) 0%, rgba(172, 118, 34) 100%);
background: -ms-linear-gradient(left, rgba(229, 175, 65, 1) 0%, rgba(172, 118, 34) 100%);
background: linear-gradient(to right, rgba(229, 175, 65, 1) 0%, rgba(172, 118, 34) 100%);
}
.slider-area .slider-img {
  text-align: center;
}
.slider-area .slider-img img {
  display: inline-block;
  width: 100%;
}
@media only screen and (max-width: 1600px) {
  .slider-area .slider-img img {
    width: 70%;
  }
}
.btc_timer_section_wrapper{
	float:left;
	width:100%;
	padding-top: 80px;
}
#clockdiv, #clockdiv2{
	color: #fff;
	display: inline-block;
	font-weight: 100;
	text-align: center;
	font-size: 70px;
}
#clockdiv > div, #clockdiv2 > div{
	padding: 0px;
	border-radius: 10px;
	background: transparent;
	display: inline-block;
  position:relative;
  margin: 0 5px;
}
#clockdiv > div:last-child:after, #clockdiv2 > div:last-child:after{
	display:none
}
#clockdiv div > span, #clockdiv2 div > span {
  font-family: 'Oswald', sans-serif;
  padding: 0px;
  border-radius: 10px;
  border: 2px dashed #a1a1a1;
  display: inline-block;
  width: 120px;
  height: 200px;
  line-height: 190px;
  position: relative;
  font-weight: 400;
}
#clockdiv div > span:after, #clockdiv2 div > span:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background: rgba(255, 255, 255, 0.20);
    width: 100%;
    height: 50%;
    z-index: 0;
    border-radius: 10px 10px 0px 0px;
}
.smalltext{
	padding-top: 15px;
	font-size: 16px;
}
.particles-js-canvas-el {
  height: auto;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}

/*....................................
4. About area start here
....................................*/
.about-area {
  display: block;
}
@media only screen and (max-width: 768px) {
  .about-area {
    padding: 45px 0 75px;
  }
}
@media only screen and (max-width: 768px) {
  .about-area .about-content {
    text-align: center;
  }
}
.about-area .about-content h2 {
  color: #ffffff;
}
.about-area .about-content h5 {
  font-size: 1rem;
  letter-spacing: 3px;
  font-weight: 600;
  color: #f25a1d;
  margin-top: 1rem;
  border-top: 1px solid #767676;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
  padding-top: 1.1rem;
}
@media only screen and (max-width: 320px) {
  .about-area .about-content h2 {
    font-size: 30px;
  }
}
.about-area .about-content .buttons a {
  margin: 0 10px;
}
@media only screen and (max-width: 320px) {
  .about-area .about-content .buttons a {
    margin: 0 0 10px;
  }
}
.about-area .about-content .buttons a:first-child {
  margin-left: 0;
}
.about-area .about-content .buttons a:last-child {
  margin-right: 0;
}
@media only screen and (max-width: 320px) {
  .about-area .about-content .buttons a:last-child {
    margin: 0;
  }
}
.about-area .about-img {
  margin-right: -150px;
}
.about-area{
	background:#000014;
}
@media only screen and (max-width: 1600px) {
  .about-area .about-img {
    margin-right: -100px;
    margin-top: 50px;
  }
}
@media only screen and (max-width: 1200px) {
  .about-area .about-img {
    margin-right: 0;
  }
}
.about-area .about-img img {
  width: 100%;
}

/*....................................
7. Blog area start here
....................................*/
.blog-area {
  background: #1a1a1a;
  position: relative;
  display: block;
}
.blog-area .blog {
  display: block;
  position: relative;
  background: #45487d;
  overflow: hidden;
  border-radius: 22px;
}
.blog-area .blog figure {
  display: block;
  position: relative;
  overflow: hidden;
}
.blog-area .blog figure img {
  width: 100%;
  display: block;
  transition: all 1.5s;
}
.blog-area .blog:hover figure img {
  transform: scale(1.2, 1.2);
  -webkit-filter: grayscale(100%);
  /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}
.blog-area .blog .content {
  display: block;
  position: relative;
  background: #1a1c35;
  padding: 30px 35px;
}
.blog-area .blog .content span {
  display: block;
  font-size: 14px;
  color: #85857d;
  margin: 0 0 25px;
}
.blog-area .blog .content span i {
  font-size: 16px;
  padding-right: 8px;
}
.blog-area .blog .content h4 {
  font-weight: 700;
}
.blog-area .blog .content h4 a {
  color: #ffffff;
  padding-right: 20px;
  display: inline-block;
}
.blog-area .blog .content h4 a:hover {
  color: #f25a1d;
}
.blog-area .blog .content .blog-btn {
  color: #f25a1d;
  font-size: 14px;
  font-weight: 900;
  text-transform: uppercase;
}
.blog-area .blog .content .blog-btn i {
  padding-left: 8px;
}
.blog-area .blog .content .blog-btn:hover {
  color: rgba(255, 255, 255, 0.61);
}
.blog-area .view-btn {
  text-align: center;
  margin-top: 80px;
  display: block;
}
@media only screen and (max-width: 992px) {
  .blog-area .view-btn {
    margin-top: 40px;
  }
}
.blog-area .view-btn a {
  display: inline-block;
  font-weight: 700;
  color: #fff;
  font-size: 14px;
  text-transform: uppercase;
  background: rgba(172, 118, 34);
background: -moz-linear-gradient(left, rgba(172, 118, 34) 0%, rgba(229, 175, 65, 1) 100%);
background: -webkit-gradient(left top, right top, color-stop(0%, rgba(172, 118, 34)), color-stop(100%, rgba(229, 175, 65, 1)));
background: -webkit-linear-gradient(left, rgba(172, 118, 34) 0%, rgba(229, 175, 65, 1) 100%);
background: -o-linear-gradient(left, rgba(172, 118, 34) 0%, rgba(229, 175, 65, 1) 100%);
background: -ms-linear-gradient(left, rgba(172, 118, 34) 0%, rgba(229, 175, 65, 1) 100%);
background: linear-gradient(to right, #f25a1d 0%, #f25a1d 100%);
  border-radius: 50px;
  padding: 0 40px;
  line-height: 50px;
  height: 50px;
}
.blog-area .view-btn a:hover{
	background: rgba(229, 175, 65, 1);
background: -moz-linear-gradient(left, rgba(229, 175, 65, 1) 0%, rgba(172, 118, 34) 100%);
background: -webkit-gradient(left top, right top, color-stop(0%, rgba(229, 175, 65, 1)), color-stop(100%, rgba(172, 118, 34)));
background: -webkit-linear-gradient(left, rgba(229, 175, 65, 1) 0%, rgba(172, 118, 34) 100%);
background: -o-linear-gradient(left, rgba(229, 175, 65, 1) 0%, rgba(172, 118, 34) 100%);
background: -ms-linear-gradient(left, rgba(229, 175, 65, 1) 0%, rgba(172, 118, 34) 100%);
background: linear-gradient(to right, rgba(229, 175, 65, 1) 0%, rgba(172, 118, 34) 100%);
}
.blog-area .owl-nav {
  margin: 0;
}
.blog-area .owl-nav {
  position: absolute;
  left: -110px;
  right: -110px;
  top: 45%;
}
@media only screen and (max-width: 1600px) {
  .blog-area .owl-nav {
    left: -80px;
    right: -80px;
  }
}

.about-content .projects {
  background: transparent !important;
}


.blog-area .owl-nav div {
  display: inline-block;
  opacity: 1;
  width: 80px;
  height: 80px;
  line-height: 80px;
  border-radius: 100%;
  background: #1a1c35;
  font-size: 40px;
  color: #fff;
  position: absolute;
  margin: 0;
  padding: 0;
  transition: all 0.5s;
  text-align: center;
}
.blog-area .owl-nav div:hover {
  background: #45487d;
}
.blog-area .owl-nav div.owl-prev {
  left: 0;
}
.blog-area .owl-nav div.owl-next {
  right: 0;
}

/*....................................
9. Contact area start here
....................................*/
/*....................................
10. Footer area start here
....................................*/
footer {
  position: relative;
}

footer .footer-bottom {
  display: block;
  background: #000014;
  height: 100px;
  line-height: 100px;
  position: relative;
}
footer .footer-bottom .copyright p {
  font-size: 16px;
  margin: 0;
  color: #fff;
  /* text-transform: uppercase; */
  display: flex;
  align-items: center;
  justify-content: center;
}
footer .footer-bottom .copyright p span {
  color: #f25a1d;
}
footer .footer-bottom .foo-link {
  text-align: right;
}
footer .footer-bottom .foo-link ul li {
  display: inline-block;
  padding: 0 20px;
  position: relative;
}
footer .footer-bottom .foo-link ul li:after {
  content: '';
  position: absolute;
  right: -2px;
  width: 2px;
  top: 5px;
  bottom: 5px;
  background: #85857d;
}
footer .footer-bottom .foo-link ul li:first-child {
  padding-left: 0;
}
footer .footer-bottom .foo-link ul li:last-child {
  padding-right: 0;
}
footer .footer-bottom .foo-link ul li:last-child:after {
  display: none;
}
footer .footer-bottom .foo-link ul li a {
  color: #fff;
  display: block;
  font-weight: 500;
  line-height: 26px;
  text-transform: uppercase;
  font-size: 16px;
}
footer .footer-bottom .foo-link ul li a:hover {
  color: #f25a1d;
}

footer .footer-top {
  background: #1a1a1a;
  display: block;
  position: relative;
  padding-top: 50px;
}
footer .footer-top .con {
  position: relative;
  z-index: 999;
  text-align: center;
  display: block;
}
footer .footer-top .con h2 {
  font-weight: 400;
  font-size: 40px;
  color: #fff;
  margin: 0 0 78px;
}
footer .footer-top .con ul li {
  display: inline-block;
  position: relative;
  padding: 0 10px;
}
footer .footer-top .con ul li:first-child {
  padding-left: 0;
}
footer .footer-top .con ul li:last-child {
  padding-right: 0;
}
footer .footer-top .con ul li a {
  display: block;
  width: 100px;
  height: 100px;
  line-height: 100px;
background: rgba(172, 118, 34);
background: -moz-linear-gradient(left, rgba(172, 118, 34) 0%, rgba(229, 175, 65, 1) 100%);
background: -webkit-gradient(left top, right top, color-stop(0%, rgba(172, 118, 34)), color-stop(100%, rgba(229, 175, 65, 1)));
background: -webkit-linear-gradient(left, rgba(172, 118, 34) 0%, rgba(229, 175, 65, 1) 100%);
background: -o-linear-gradient(left, rgba(172, 118, 34) 0%, rgba(229, 175, 65, 1) 100%);
background: -ms-linear-gradient(left, rgba(172, 118, 34) 0%, rgba(229, 175, 65, 1) 100%);
background: linear-gradient(to right, #f25a1d 0%, #f25a1d 100%);


  text-align: center;
  color: #ffffff;
  font-size: 42px;
  border-radius: 100%;
}
footer .footer-top .con ul li a:hover {
  color: #fff;
  background: rgba(229, 175, 65, 1);
  background: -webkit-gradient(left top, right top, color-stop(0%, rgba(229, 175, 65, 1)), color-stop(100%, rgba(172, 118, 34)));
  background: linear-gradient(to right, rgb(182 57 30) 0%, rgb(182 58 30) 100%);
}

/*....................................
11. Breadcumb area start here
....................................*/
/*....................................
6. Banner area start here
....................................*/
.video-des .deslidt {
  display: block;
}
.video-des .deslidt ul li {
  position: relative;
  display: block;
  overflow: hidden;
  text-align: center;
  margin-bottom: 30px;
}
@media only screen and (max-width: 768px) {
  .video-des .deslidt ul li {
    margin: 0;
  }
}
.video-des .deslidt ul li .years {
  display: block;
  margin-bottom: -40px;
}
@media only screen and (max-width: 992px) {
  .video-des .deslidt ul li .years {
    margin: 0;
  }
}
@media only screen and (max-width: 768px) {
  .video-des .deslidt ul li .years {
    margin: 0 0 30px;
  }
}
.video-des .deslidt ul li .years span.con {
  z-index: 999;
  width: 90px;
  height: 90px;
  margin: 0 auto;
    background: rgba(172, 118, 34);
background: -moz-linear-gradient(left, rgba(172, 118, 34) 0%, rgba(229, 175, 65, 1) 100%);
background: -webkit-gradient(left top, right top, color-stop(0%, rgba(172, 118, 34)), color-stop(100%, rgba(229, 175, 65, 1)));
background: -webkit-linear-gradient(left, rgba(172, 118, 34) 0%, rgba(229, 175, 65, 1) 100%);
background: -o-linear-gradient(left, rgba(172, 118, 34) 0%, rgba(229, 175, 65, 1) 100%);
background: -ms-linear-gradient(left, rgba(172, 118, 34) 0%, rgba(229, 175, 65, 1) 100%);
background: linear-gradient(to right, #f25a1d 0%, #f25a1d 100%);
  color: #fff;
  line-height: 90px;
  border-radius: 100%;
  font-size: 14px;
  font-weight: 700;
  display: block;
  position: relative;
}
.video-des .deslidt ul li .years:before {
  content: '\f078';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 40px;
  font-size: 16px;
  font-family: FontAwesome;
  color: #1a1a1a;
  z-index: 9;
}
.video-des .deslidt ul li:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 60px;
  width: 3px;
  background: #1a1a1a;
  margin: 0 auto;
  z-index: 9;
}
.video-des .deslidt ul li .left-con {
  display: inline-block;
  text-align: left;
}
.video-des .deslidt ul li .left-con .box-con {
  display: inline-block;
  position: relative;
  margin-top: 14px;
}
@media only screen and (max-width: 768px) {
  .video-des .deslidt ul li .left-con .box-con {
    display: block;
  }
}
.video-des .deslidt ul li .left-con .box-con:after {
  content: '';
  position: absolute;
  left: 60px;
  right: 60px;
  top: -14px;
  bottom: 0;
  border-radius: 30px;
  background: rgba(172, 118, 34);
background: -moz-linear-gradient(left, rgba(172, 118, 34) 0%, rgba(229, 175, 65, 1) 100%);
background: -webkit-gradient(left top, right top, color-stop(0%, rgba(172, 118, 34)), color-stop(100%, rgba(229, 175, 65, 1)));
background: -webkit-linear-gradient(left, rgba(172, 118, 34) 0%, rgba(229, 175, 65, 1) 100%);
background: -o-linear-gradient(left, rgba(172, 118, 34) 0%, rgba(229, 175, 65, 1) 100%);
background: -ms-linear-gradient(left, rgba(172, 118, 34) 0%, rgba(229, 175, 65, 1) 100%);
background: linear-gradient(to right, #f25a1d 0%, #f25a1d 100%);
  z-index: 99;
}
.video-des .deslidt ul li .left-con .box-con .dbox {
    background: #1a1a1a;
    border-radius: 22px;
    width: 400px;
    height: 195px;
    display: table-cell;
    vertical-align: middle;
    padding: 20px 40px;
    position: relative;
    z-index: 999;
    transition: all 0.5s;
}
@media only screen and (max-width: 992px) {
  .video-des .deslidt ul li .left-con .box-con .dbox {
    width: 100%;
    padding: 0 20px;
  }
}
@media only screen and (max-width: 768px) {
  .video-des .deslidt ul li .left-con .box-con .dbox {
    display: block;
    padding: 40px;
    margin: 0 0 30px;
  }
}
@media only screen and (max-width: 320px) {
  .video-des .deslidt ul li .left-con .box-con .dbox {
    padding: 40px 25px;
  }
}
.video-des .deslidt ul li .left-con .box-con .dbox .imgs {
  display: block;
  width: 110px;
  height: 110px;
  border: 3px solid #4e5186;
  line-height: 104px;
  border-radius: 100%;
  text-align: center;
}
@media only screen and (max-width: 320px) {
  .video-des .deslidt ul li .left-con .box-con .dbox .imgs {
    width: 60px;
    height: 60px;
    line-height: 54px;
  }
}
.video-des .deslidt ul li .left-con .box-con .dbox .imgs img {
  display: inline-block;
}
.video-des .deslidt ul li .left-con .box-con .dbox .content {
  padding-left: 10px;
  display: block;
  padding: 0;
}
.video-des .deslidt ul li .left-con .box-con .dbox .content h3 {
  margin: 0;
  font-weight: 400;
  color: #ffffff;
  margin-bottom: 12px;
}
@media only screen and (max-width: 992px) {
  .video-des .deslidt ul li .left-con .box-con .dbox .content h3 {
    font-size: 20px;
  }
}
@media only screen and (max-width: 480px) {
  .video-des .deslidt ul li .left-con .box-con .dbox .content h3,
.video-des .deslidt ul li .center-con .box-con .dbox .content h3  {
    font-size: 18px !important;
  }
  .video-des .deslidt ul li .left-con .box-con .dbox .imgs img,
	.video-des .deslidt ul li .right-con .box-con .dbox .imgs img,
.video-des .deslidt ul li .center-con .box-con .dbox .imgs img	{
    width: 46% !important;
    margin: 0 auto !important;
  }
  .video-des .deslidt ul li .left-con .box-con .dbox .imgs,
  .video-des .deslidt ul li .right-con .box-con .dbox .imgs,
  .video-des .deslidt ul li .center-con .box-con .dbox .imgs{
	  width:40px !important;
	  height:40px !important;
	  line-height:30px !important;
  }
  .video-des .deslidt ul li .left-con .box-con .dbox .content,
  .video-des .deslidt ul li .right-con .box-con .dbox .content,
  .video-des .deslidt ul li .center-con .box-con .dbox .content{
	  padding:0 !important;
  }
  .video-des .deslidt ul li .left-con .box-con .dbox,
  .video-des .deslidt ul li .right-con .box-con .dbox,
  .video-des .deslidt ul li .center-con .box-con .dbox
  {
	  padding:40px !important;
  }
}
.video-des .deslidt ul li .left-con .box-con .dbox .content p {
  font-size: 16px;
  color: rgba(255, 255, 255, 0.61);
  margin: 0;
  position: relative;
  padding-left: 18px;
}

.video-des .deslidt ul li .right-con .box-con .dbox .content p{
  padding-right: 18px;
  position: relative;
}
.video-des .deslidt ul li .left-con .box-con .dbox .content p:before {
  content: "";
  height: 5px;
  width: 5px;
  background-color: #fff;
  position: absolute;
  top: 12px;
  left: 0;
  border-radius: 5px;
}
.video-des .deslidt ul li .right-con .box-con .dbox .content p:before {
  content: "";
  height: 5px;
  width: 5px;
  background-color: #fff;
  position: absolute;
  top: 12px;
  right: 0;
  border-radius: 5px;
}


@media only screen and (max-width: 992px) {
  .video-des .deslidt ul li .left-con .box-con .dbox .content p {
    font-size: 14px;
  }
}
.video-des .deslidt ul li .left-con .box-con:hover .dbox {
  transform: translateY(-7px);
}
.video-des .deslidt ul li .right-con {
  display: inline-block;
  text-align: right;
}
.video-des .deslidt ul li .right-con .box-con {
  display: inline-block;
  position: relative;
  margin-top: 14px;
}
@media only screen and (max-width: 768px) {
  .video-des .deslidt ul li .right-con .box-con {
    display: block;
  }
}
.video-des .deslidt ul li .right-con .box-con:after {
  content: '';
  position: absolute;
  left: 60px;
  right: 60px;
  top: -14px;
  bottom: 0;
  border-radius: 30px;
  background: rgba(172, 118, 34);
background: -moz-linear-gradient(left, rgba(172, 118, 34) 0%, rgba(229, 175, 65, 1) 100%);
background: -webkit-gradient(left top, right top, color-stop(0%, rgba(172, 118, 34)), color-stop(100%, rgba(229, 175, 65, 1)));
background: -webkit-linear-gradient(left, rgba(172, 118, 34) 0%, rgba(229, 175, 65, 1) 100%);
background: -o-linear-gradient(left, rgba(172, 118, 34) 0%, rgba(229, 175, 65, 1) 100%);
background: -ms-linear-gradient(left, rgba(172, 118, 34) 0%, rgba(229, 175, 65, 1) 100%);
background: linear-gradient(to right, #f25a1d 0%, #f25a1d 100%);
  z-index: 99;
}
.video-des .deslidt ul li .right-con .box-con .dbox {
  background: #1a1a1a;
  border-radius: 22px;
  width: 400px;
  height: 195px;
  display: table-cell;
  vertical-align: middle;
  padding: 0 40px;
  position: relative;
  z-index: 999;
  transition: all 0.5s;
}
@media only screen and (max-width: 992px) {
  .video-des .deslidt ul li .right-con .box-con .dbox {
    width: 100%;
    padding: 0 20px;
  }
}
@media only screen and (max-width: 768px) {
  .video-des .deslidt ul li .right-con .box-con .dbox {
    display: block;
    padding: 40px;
    margin: 0 0 30px;
  }
}
@media only screen and (max-width: 320px) {
  .video-des .deslidt ul li .right-con .box-con .dbox {
    padding: 40px 15px;
  }
}
.video-des .deslidt ul li .right-con .box-con .dbox .dleft {
  float: inherit;
}
@media only screen and (max-width: 768px) {
  .video-des .deslidt ul li .right-con .box-con .dbox .dleft {
    float: inherit;
  }
}
.video-des .deslidt ul li .right-con .box-con .dbox .dright {
  float: right;
}
@media only screen and (max-width: 768px) {
  .video-des .deslidt ul li .right-con .box-con .dbox .dright {
    float: right;
  }
}
.video-des .deslidt ul li .right-con .box-con .dbox .imgs {
  display: block;
  width: 110px;
  height: 110px;
  border: 3px solid #4e5186;
  line-height: 104px;
  border-radius: 100%;
  text-align: center;
}
@media only screen and (max-width: 320px) {
  .video-des .deslidt ul li .right-con .box-con .dbox .imgs {
    width: 60px;
    height: 60px;
    line-height: 54px;
  }
}
.video-des .deslidt ul li .right-con .box-con .dbox .imgs img {
  display: inline-block;
}
@media only screen and (max-width: 320px) {
  .video-des .deslidt ul li .right-con .box-con .dbox .imgs img {
    width: 50%;
    margin: 0 auto;
  }
}
.video-des .deslidt ul li .right-con .box-con .dbox .content {
  padding-right: 10px;
  display: block;
  padding: 0;
}
.video-des .deslidt ul li .right-con .box-con .dbox .content h3 {
  margin: 0;
  font-weight: 400;
  margin-bottom: 12px;
  color: #ffffff;
}
@media only screen and (max-width: 992px) {
  .video-des .deslidt ul li .right-con .box-con .dbox .content h3 {
    font-size: 20px;
  }
}
@media only screen and (max-width: 320px) {
  .video-des .deslidt ul li .right-con .box-con .dbox .content h3 {
    font-size: 18px;
  }
}
.video-des .deslidt ul li .right-con .box-con .dbox .content p {
  font-size: 16px;
  color: rgba(255, 255, 255, 0.61);
  margin: 0;
}
@media only screen and (max-width: 992px) {
  .video-des .deslidt ul li .right-con .box-con .dbox .content p {
    font-size: 14px;
  }
}
.video-des .deslidt ul li .right-con .box-con:hover .dbox {
  transform: translateY(-7px);
}
.video-des .deslidt ul li .center-con {
  display: inline-block;
  text-align: center;
}
.video-des .deslidt ul li .center-con .box-con {
  display: inline-block;
  position: relative;
  margin-top: 14px;
}
@media only screen and (max-width: 480px) {
  .video-des .deslidt ul li .center-con .box-con {
    display: block;
  }
}
.video-des .deslidt ul li .center-con .box-con:after {
  content: '';
  position: absolute;
  left: 60px;
  right: 60px;
  top: -14px;
  bottom: 0;
  border-radius: 30px;
  background: rgba(172, 118, 34);
background: -moz-linear-gradient(left, rgba(172, 118, 34) 0%, rgba(229, 175, 65, 1) 100%);
background: -webkit-gradient(left top, right top, color-stop(0%, rgba(172, 118, 34)), color-stop(100%, rgba(229, 175, 65, 1)));
background: -webkit-linear-gradient(left, rgba(172, 118, 34) 0%, rgba(229, 175, 65, 1) 100%);
background: -o-linear-gradient(left, rgba(172, 118, 34) 0%, rgba(229, 175, 65, 1) 100%);
background: -ms-linear-gradient(left, rgba(172, 118, 34) 0%, rgba(229, 175, 65, 1) 100%);
background: linear-gradient(to right, #f25a1d 0%, #f25a1d 100%);
  z-index: 99;
}
.video-des .deslidt ul li .center-con .box-con .dbox {
  background: #1a1a1a;
  border-radius: 22px;
  width: 400px;
  height: 260px;
  display: table-cell;
  vertical-align: middle;
  padding: 30px 40px;
  position: relative;
  z-index: 999;
  transition: all 0.5s;
  text-align: center;
}
@media only screen and (max-width: 480px) {
  .video-des .deslidt ul li .center-con .box-con .dbox {
    display: block;
    width: 100%;
    padding: 40px;
    margin: 0 0 20px;
  }
}
@media only screen and (max-width: 320px) {
  .video-des .deslidt ul li .center-con .box-con .dbox {
    padding: 40px 25px;
  }
}
.video-des .deslidt ul li .center-con .box-con .dbox .imgs {
  display: block;
  width: 110px;
  height: 110px;
  border: 3px solid #4e5186;
  line-height: 104px;
  border-radius: 100%;
  text-align: center;
}
@media only screen and (max-width: 320px) {
  .video-des .deslidt ul li .center-con .box-con .dbox .imgs {
    width: 60px;
    height: 60px;
    line-height: 54px;
  }
}
.video-des .deslidt ul li .center-con .box-con .dbox .imgs img {
  display: inline-block;
}
@media only screen and (max-width: 320px) {
  .video-des .deslidt ul li .center-con .box-con .dbox .imgs img {
    width: 50%;
    margin: 0 auto;
  }
}
.video-des .deslidt ul li .center-con .box-con .dbox .content {
  padding-left: 10px;
  display: block;
  padding: 0;
  text-align: left;
}
.video-des .deslidt ul li .center-con .box-con .dbox .content h3 {
  margin: 0;
  font-weight: 400;
  color: #ffffff;
  margin-bottom: 12px;
}
@media only screen and (max-width: 320px) {
  .video-des .deslidt ul li .center-con .box-con .dbox .content h3 {
    font-size: 18px;
  }
}
.video-des .deslidt ul li .center-con .box-con .dbox .content p {
  font-size: 16px;
  color: rgba(255, 255, 255, 0.61);
  margin: 0;
  position: relative;
  padding-left: 18px;
}

.video-des .deslidt ul li .center-con .box-con .dbox .content p:before {
  content: "";
  height: 5px;
  width: 5px;
  background-color: #fff;
  position: absolute;
  top: 12px;
  left: 0;
  border-radius: 5px;
}



.video-des .deslidt ul li .center-con .box-con:hover .dbox {
  transform: translateY(-7px);
}
.video-des .deslidt ul li:first-child .left-con .content {
  padding: 28px 0 !important;
}
.video-des .deslidt ul li:first-child .right-con .content {
  padding: 28px 0 !important;
}
.video-des .deslidt ul li:first-child {
  padding-top: 100px;
}
@media only screen and (max-width: 768px) {
  .video-des .deslidt ul li:first-child {
    padding-top: 75px;
  }
}
.video-des .deslidt ul li:last-child {
  padding-bottom: 100px;
  margin: 0 0 45px;
}
@media only screen and (max-width: 768px) {
  .video-des .deslidt ul li:last-child {
    padding-bottom: 75px;
  }
}
.video-des .deslidt ul li:last-child .years:after {
  display: none;
}
.video-des .deslidt ul li:last-child .years:before {
  bottom: 0;
}
.video-des .deslidt ul li:last-child:after {
  bottom: 20px;
}
.video-des .deslidt ul li .serials {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0 0 80px;
}
.video-des .deslidt ul li .serials:last-child {
  padding: 0;
}
.video-des .deslidt ul li .serials:before {
  content: '';
  position: absolute;
  left: 40%;
  right: 40%;
  top: 100px;
  height: 3px;
  background: #1a1a1a;
  margin: 0 auto;
}
.video-des .deslidt ul li .serials:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 90px;
  background: #1a1a1a;
  width: 22px;
  height: 22px;
  border-radius: 100%;
  margin: 0 auto;
  z-index:10;
}
.video-des .deslidt ul li .serials span.cre:after {
  content: '';
  position: absolute;
  left: 40%;
  width: 8px;
  height: 8px;
  background: #1a1a1a;
  border-radius: 100%;
  bottom: 98px;
}
.video-des .deslidt ul li .serials span.cre:before {
  content: '';
  position: absolute;
  right: 40%;
  width: 8px;
  height: 8px;
  background: #1a1a1a;
  border-radius: 100%;
  bottom: 98px;
}

.currency-area {
  display: block;
  /* position: relative; */
  overflow: unset;
  z-index: 100;
  background: #1a1c35;
}
.currency-area .rete-list {
  display: inline-block;
  position: relative;
  width: 20%;
  padding: 0 17px;
  margin: 0 0 30px;
  padding-top: 15px;
  z-index: 999;
  margin-top: -10vh;
}
@media only screen and (max-width: 1600px) {
  .currency-area .rete-list {
    padding-left: 8px;
    padding-right: 8px;
    padding-top: 10px;
  }
}
@media only screen and (max-width: 1200px) {
  .currency-area .rete-list {
    padding: 0;
  }
}
@media only screen and (max-width: 992px) {
  .currency-area .rete-list {
    width: 50%;
	float:left !important;
    padding-left: 8px;
    padding-right: 8px;

  }
}
@media (max-width: 580px){
	 .currency-area .rete-list {
		width: 100%;
		float:left !important;

	  }
}
@media only screen and (max-width: 480px) {
  .currency-area .rete-list {
    width: 100%;
  }
}
.currency-area .rete-list:after {
  content: '';
  position: absolute;
  left: 50px;
  right: 50px;
  top: 0;
  bottom: 0;
  background: rgba(172, 118, 34);
background: -moz-linear-gradient(left, rgba(172, 118, 34) 0%, rgba(229, 175, 65, 1) 100%);
background: -webkit-gradient(left top, right top, color-stop(0%, rgba(172, 118, 34)), color-stop(100%, rgba(229, 175, 65, 1)));
background: -webkit-linear-gradient(left, rgba(172, 118, 34) 0%, rgba(229, 175, 65, 1) 100%);
background: -o-linear-gradient(left, rgba(172, 118, 34) 0%, rgba(229, 175, 65, 1) 100%);
background: -ms-linear-gradient(left, rgba(172, 118, 34) 0%, rgba(229, 175, 65, 1) 100%);
background: linear-gradient(to right, #f25a1d 0%, #f25a1d 100%);
  border-radius: 10px;
  z-index: 9999;
}
@media only screen and (max-width: 1600px) {
  .currency-area .rete-list:after {
    left: 30px;
    right: 30px;
  }
}
.currency-area .rete-list .content {
  background: #1a1a1a;
  padding: 0 50px;
  display: block;
  border-radius: 10px;
  z-index: 99999;
  position: relative;
}
@media only screen and (max-width: 1600px) {
  .currency-area .rete-list .content {
    padding: 0 25px;
  }
}
@media only screen and (max-width: 1200px) {
  .currency-area .rete-list .content {
    padding: 0 15px;
  }
}
@media only screen and (max-width: 992px) {
  .currency-area .rete-list .content {
    padding: 0 30px;
  }
}
.currency-area .rete-list .content .con {
  height: 230px;
  display: table-cell;
  vertical-align: middle;
}
@media only screen and (max-width: 1600px) {
  .currency-area .rete-list .content .con {
    height: 180px;
  }
}
.currency-area .rete-list h2 {
  font-size: 22px;
  font-weight: 900;
  color: #ffffff;
  text-transform: capitalize;
}
@media only screen and (max-width: 1600px) {
  .currency-area .rete-list h2 {
    font-size: 20px;
  }
}
@media only screen and (max-width: 1200px) {
  .currency-area .rete-list h2 {
    font-size: 20px;
  }
}
@media only screen and (max-width: 992px) {
  .currency-area .rete-list h2 {
    font-size: 26px;
  }
}
.currency-area .rete-list h2 img {
  width: 50px;
  height: 50px;
}
@media only screen and (max-width: 1600px) {
  .currency-area .rete-list h2 img {
    width: 44px;
  }
}
.currency-area .rete-list h2 span {
  padding-left: 8px;
}
@media only screen and (max-width: 992px) {
  .currency-area .rete-list:last-child {
    float: initial;
    margin: 0 auto 30px;
    display: block;
    z-index: 9;
  }
}
@media only screen and (max-width: 480px) {
  .currency-area .rete-list:last-child {
    height: auto;
    display: inline-block;
    width: 100%;
  }
}

.projects {
  display: block;
}
@media only screen and (max-width: 768px) {
  .projects {
    padding: 75px 0 37px;
  }
}
.projects .project-list {
  position: relative;
  display: block;
  background: #1c110000;
  margin: 0 0 38px;
  padding-top: 14px;
}
.projects .project-list:after {
  content: '';
  position: absolute;
  left: 40px;
  right: 40px;
  top: 0;
  bottom: 0;
  border-radius: 15px;
  background: rgba(172, 118, 34);
	background: -moz-linear-gradient(left, rgba(172, 118, 34) 0%, rgba(229, 175, 65, 1) 100%);
	background: -webkit-gradient(left top, right top, color-stop(0%, rgba(172, 118, 34)), color-stop(100%, rgba(229, 175, 65, 1)));
	background: -webkit-linear-gradient(left, rgba(172, 118, 34) 0%, rgba(229, 175, 65, 1) 100%);
	background: -o-linear-gradient(left, rgba(172, 118, 34) 0%, rgba(229, 175, 65, 1) 100%);
	background: -ms-linear-gradient(left, rgba(172, 118, 34) 0%, rgba(229, 175, 65, 1) 100%);
	background: linear-gradient(to right, #f25a1d 0%, #f25a1d 100%);
  z-index: 9;
}
.projects .project-list .content {
  display: block;
  background: #1a1a1a;
  text-align: center;
  padding: 30px 0 40px;
  border-radius: 15px;
  position: relative;
  z-index: 999;
  transition: all 0.5s;
}
.projects .project-list .content:hover {
  transform: translateY(-7px);
}
.projects .project-list .content span {
  display: block;
  height: 110px;
  margin: 0 0 10px;
}
.projects .project-list .content span * {
  width: 100px;
  height: 100px;
  object-fit: scale-down;
}

body > iframe {
  pointer-events: none !important;
}

.projects .project-list .content h3 {
  color: #ffffff;
  margin: 0;
  line-height: 24px;
  font-size: 18px;
  font-weight: 500;
}

.buttons {
  display: flex;
  align-items: start;
}
.buttons span {
  display: block;
  align-items: center;
  font-size: 20px;
  color: #fff;
  font-weight: 600;
  margin-left: 14px;
  line-height: 1.7;
}
.buttons span  small {
  display: block;
  font-size: 14px;
  font-weight: 300;
  line-height: 1;
}


.ss_menu_item {
  position: relative;
}


.ss_menu_item.dropdown .dropdown-toggle {
  line-height: 50px;
  padding: 0 25px !important;
  font-size: 16px;
  max-width: unset !important;
  width: auto !important;
  cursor: pointer;
}

.ss_menu_item.dropdown .dropdown-toggle:before{
  display: none;
}

.about-content .content p {
  font-size: 15px;
  line-height: 1.5;
  opacity: 0.7;
  margin-top: 13px;
  min-height: 92px;
}


.projects{
	background:#000;
}
.media-area .mediabrand ul {
  display: block;
  margin: -30px;
}
.media-area{
	background:#1a1c35;
}
.media-area .mediabrand ul li {
  display: inline-block;
  padding: 30px;
}

.tokens-area {
  background: #1a1a1a;
  position: relative;
}
.tokens-area .tokens {
  display: inline-block;
  position: relative;
  text-align: center;
}
.tokens-area .tokens .token-name {
  display: inline-block;
  color: #fff;
  background: rgba(172, 118, 34);
background: -moz-linear-gradient(left, rgba(172, 118, 34) 0%, rgba(229, 175, 65, 1) 100%);
background: -webkit-gradient(left top, right top, color-stop(0%, rgba(172, 118, 34)), color-stop(100%, rgba(229, 175, 65, 1)));
background: -webkit-linear-gradient(left, rgba(172, 118, 34) 0%, rgba(229, 175, 65, 1) 100%);
background: -o-linear-gradient(left, rgba(172, 118, 34) 0%, rgba(229, 175, 65, 1) 100%);
background: -ms-linear-gradient(left, rgba(172, 118, 34) 0%, rgba(229, 175, 65, 1) 100%);
background: linear-gradient(to right, #f25a1d 0%, #f25a1d 100%);
  line-height: 42px;
  height: 42px;
  padding: 0 45px;
  border-radius: 15px 15px 0 0;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 900;
  position: relative;
  z-index: 999;
}
.tokens-area .tokens .token-body {
	background: #000014;
	min-height: 545px;
	padding: 34px 25px;
	display: inline-block;
	border-radius: 22px;
	position: relative;
	z-index: 999;
  width: 100%;
}
.tokens-area .tokens .token-body p {
  font-weight: 700;
  font-size: 18px;
  color: #ffffff;
}
.tokens-area .tokens .token-body .left-btn {
  display: inline-block;
  height: 60px;
  line-height: 54px;
  padding: 0 45px;
  border-radius: 50px;
  border: 3px solid #85857d;
  font-size: 20px;
  font-weight: 900;
  color: #ffffff;
  background: transparent;
}
td hr {
	margin: 1rem 0 1rem;
	border-color: #1a1a1a;
}
.tokens-area .tokens .token-body .right-btn {
  display: inline-block;
  height: 60px;
  line-height: 54px;
  padding: 0 45px;
  border-radius: 50px;
  font-size: 20px;
  font-weight: 900;
  color: #fff;
  background: rgba(172, 118, 34);
background: -moz-linear-gradient(left, rgba(172, 118, 34) 0%, rgba(229, 175, 65, 1) 100%);
background: -webkit-gradient(left top, right top, color-stop(0%, rgba(172, 118, 34)), color-stop(100%, rgba(229, 175, 65, 1)));
background: -webkit-linear-gradient(left, rgba(172, 118, 34) 0%, rgba(229, 175, 65, 1) 100%);
background: -o-linear-gradient(left, rgba(172, 118, 34) 0%, rgba(229, 175, 65, 1) 100%);
background: -ms-linear-gradient(left, rgba(172, 118, 34) 0%, rgba(229, 175, 65, 1) 100%);
background: linear-gradient(to right, #f25a1d 0%, #f25a1d 100%);
}
.tokens-area .tokens .token-body .right-btn:hover{
	background: rgba(229, 175, 65, 1);
background: -moz-linear-gradient(left, rgba(229, 175, 65, 1) 0%, rgba(172, 118, 34) 100%);
background: -webkit-gradient(left top, right top, color-stop(0%, rgba(229, 175, 65, 1)), color-stop(100%, rgba(172, 118, 34)));
background: -webkit-linear-gradient(left, rgba(229, 175, 65, 1) 0%, rgba(172, 118, 34) 100%);
background: -o-linear-gradient(left, rgba(229, 175, 65, 1) 0%, rgba(172, 118, 34) 100%);
background: -ms-linear-gradient(left, rgba(229, 175, 65, 1) 0%, rgba(172, 118, 34) 100%);
background: linear-gradient(to right, rgba(229, 175, 65, 1) 0%, rgba(172, 118, 34) 100%);
}
.tokens-area .tokens .token-body .prices h3 {
	font-weight: inherit;
	color: #ffffff;
	margin: 20px 0;
}
.tokens-area .tokens .token-body .prices h4 {
	font-weight: normal;
	/* color: #fff; */
}
.tokens-area .tokens .token-body .prices table {
	margin: 0 auto;
	text-align: center;
	width: 100%;
}
td hr {
	margin: 1rem 0 1.5rem;
}
.text-white {
	color: #fff !important;
}
.tokens-area .tokens .token-body .prices table tr th {
  font-size: 20px;
  color: #f25a1d;
  margin: 0 0 22px;
  text-transform: capitalize;
  padding: 0 10px;
}
.tokens-area .tokens .token-body .prices table tr td {
  font-size: 20px;
  color: rgba(255, 255, 255, 0.61);
  padding: 7px 15px;
}
.tokens-area .tokens .token-body .prices table tr td:last-child {
  text-align: center;
}
.tokens-area .tokens .token-body .prices table tr td:last-child {
  /* display: flex; */
  text-align: left;
  line-height: 1.1;
}

.tokens-area .tokens .token-body .prices table tr td small {
  margin: 0;
  font-size: 70%;
}
.tokens-area .token-con {
  color: #fff;
  margin: 50px 0 0;
  /* font-size: 20px; */
}

.token-details .cart-list {
  display: block;
  background: #242424;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
  overflow: hidden;
  border-radius: 15px;
  padding: 30px 0;
  margin: 5px;
  position: relative;
  align-items: center;
  display: flex;
}
.token-details .cart-list .imgs {
  /* position: absolute; */
  /* top: 32%; */
  /* left: 0; */
  /* right: 0; */
  width: 100%;
  margin: 0 auto;
  text-align: center;
  display: inline-block;
  border-radius: 100%;
}
.token-details .cart-list .lsits {
  display: block;
  margin: 0 0 20px;
  position: relative;
}
.token-details .cart-list .lsits:after {
  content: '';
  position: absolute;
  left: 0;
  top: 7px;
  width: 12px;
  height: 12px;
  border-radius: 100%;
  background: #f25a1d;
}
.token-details .cart-list .lsits:nth-child(1) {
  opacity: 1;
}
.token-details .cart-list .lsits:nth-child(2) {
  opacity: 0.8;
}
.token-details .cart-list .lsits:nth-child(3) {
  opacity: 0.6;
}
.token-details .cart-list .lsits:nth-child(4) {
  opacity: 0.4;
  margin: 0;
}
.token-details .cart-list .label-chatrs {
  display: block;
  padding: 62px 12px 62px 0;
}
.token-details .cart-list .label-chatrs .clabels-text {
  font-size: 24px;
  font-weight: 700;
  color: #ffffff;
  padding-left: 20px;
}
.token-details .cart-list .label-chatrs p {
  margin: 7.5px  0 12px;
}
.token-details .owl-wrapper-outer {
  z-index: 999;

}
.token-details .owl-nav {
  margin: 0;
}
.token-details .owl-nav {
  position: absolute;
  left: 0;
  right: 0;
  top: 50px;
  bottom: 50px;
  z-index: 9;
}
.token-details .owl-nav div {
  position: absolute;
  display: block;
  width: 70px;
  top: 0;
  bottom: 0;
  background: #1a1a1a;
  color: #fff;
  opacity: 1;
  font-size: 50px;
  transition: all 0.5s;
}
.token-details .owl-nav div:hover {
  background: rgba(172, 118, 34);
background: -moz-linear-gradient(left, rgba(172, 118, 34) 0%, rgba(229, 175, 65, 1) 100%);
background: -webkit-gradient(left top, right top, color-stop(0%, rgba(172, 118, 34)), color-stop(100%, rgba(229, 175, 65, 1)));
background: -webkit-linear-gradient(left, rgba(172, 118, 34) 0%, rgba(229, 175, 65, 1) 100%);
background: -o-linear-gradient(left, rgba(172, 118, 34) 0%, rgba(229, 175, 65, 1) 100%);
background: -ms-linear-gradient(left, rgba(172, 118, 34) 0%, rgba(229, 175, 65, 1) 100%);
background: linear-gradient(to right, #f25a1d 0%, #f25a1d 100%);
}
.token-details .owl-nav div i {
  line-height: 330px;
  width: 100%;
  text-align: center;
}
.token-details .owl-nav div.owl-prev {
  left: -65px;
  border-radius: 15px 0 0 15px;
}
.token-details .owl-nav div.owl-next {
  right: -65px;
  border-radius: 0 15px 15px 0;
}
.token-details{
	background:#000;
}
.token-details .contents h2 {
  font-weight: 400;
  margin: 50px 0 30px;
  color: #ffffff;
}

.easypiechart {
  position: relative;
  display: inline-block;
  margin-top: 55px;
      border: 1px solid rgba(255, 255, 255, 0.48);
        width: 150px;
    height: 150px;
    padding-top: 48px;
    border-radius: 100%;
}
.easypiechart .percent {
  text-align: center;
  font-size: 46px;
  font-weight: 900;
  color: #ffffff;
}
.easypiechart .percent:after {
  content: "%";
  font-size: 46px;
  font-weight: 900;
  margin-left: 1px;
}
.easypiechart .con {
  font-size: 16px;
  font-weight: 700;
  color: rgba(255, 255, 255, 0.61);
}
.easypiechart canvas {
  width: 180px;
  height: 180px;
}
.easypiechart canvas:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border: 3px solid #000;
}

.features-area {
  position: relative;
  background: #1a1a1a;
  z-index:1;
}
@media only screen and (max-width: 768px) {
  .features-area .featured-lists .floatright {
    float: inherit !important;
    display: block;
  }
}
.features-area .featured-lists ul {
  position: relative;
  display: block;
  overflow: hidden;
  z-index: 9999;
}
@media only screen and (max-width: 768px) {
  .features-area .featured-lists ul {
    text-align: center;
  }
}
.features-area .featured-lists ul:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #85857d;
  width: 2px;
  margin: 0 auto;
}
@media only screen and (max-width: 768px) {
  .features-area .featured-lists ul:after {
    display: none;
  }
}
.features-area .featured-lists ul li {
  position: relative;
  display: block;
  padding: 40px 0 0;
  overflow: hidden;
}
@media only screen and (max-width: 768px) {
  .features-area .featured-lists ul li {
    padding: 30px 0 0;
  }
}
@media only screen and (max-width: 768px) {
  .features-area .featured-lists ul li:first-child {
    padding: 0;
  }
}
.features-area .featured-lists ul li:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 90px;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background: #85857d;
}
@media only screen and (max-width: 768px) {
  .features-area .featured-lists ul li:after {
    display: none;
  }
}
.features-area .featured-lists ul li:before {
  content: '';
  position: absolute;
  left: calc(50% - 80px);
  right: 0;
  width: 250px;
  height: 2px;
  background: #85857d;
  top: 99px;
}
@media only screen and (max-width: 768px) {
  .features-area .featured-lists ul li:before {
    display: none;
  }
}
.features-area .featured-lists ul li.rl:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 90px;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background: #85857d;
}
@media only screen and (max-width: 768px) {
  .features-area .featured-lists ul li.rl:after {
    display: none;
  }
}
.features-area .featured-lists ul li.rl:before {
  content: '';
  position: absolute;
  right: calc(50% - 80px);
  left: auto;
  width: 250px;
  height: 2px;
  background: #85857d;
  top: 99px;
}
@media only screen and (max-width: 768px) {
  .features-area .featured-lists ul li.rl:before {
    display: none;
  }
}
.features-area .featured-lists ul li .contents-l {
  display: block;
  padding: 40px 0;
  text-align: right;
  margin-right: 90px;
}
@media only screen and (max-width: 768px) {
  .features-area .featured-lists ul li .contents-l {
    padding: 0;
  }
}
.features-area .featured-lists ul li .contents-l h2 {
  color: #fff;
  font-weight: 400;
  letter-spacing: 1px;
  margin: 0 0 20px;
}
@media only screen and (max-width: 768px) {
  .features-area .featured-lists ul li .contents-l h2 {
    font-size: 20px;
    margin: 0 0 13px;
}
.features-area .featured-lists ul li .contents-l p {
  margin: 0 auto 0 !important;
  max-width: 90%;
  line-height: 1.4;
  font-size: 14px;
}


}
.features-area .featured-lists ul li .contents-l p {
  color: #85857d;
  margin: 0 0 20px;
  /* max-width: 95%; */
}
.features-area .featured-lists ul li .contents-l a {
  font-size: 14px;
  font-weight: 700;
}
.features-area .featured-lists ul li .imgs-l {
  position: relative;
  display: block;
  margin: 0 0 0 70px;
  text-align: left;
}
@media only screen and (max-width: 768px) {
  .features-area .featured-lists ul li .imgs-l {
    margin: 0;
    text-align: center;
  }
}
.features-area .featured-lists ul li .imgs-l img {
  display: inline-block;
}
.features-area .featured-lists ul li .contents-r {
  display: block;
  padding: 40px 0;
  margin-left: 90px;
}
@media only screen and (max-width: 768px) {
  .features-area .featured-lists ul li .contents-r {
    margin: 0;
    padding: 0;
  }
}
.features-area .featured-lists ul li .contents-r h2 {
  color: #fff;
  font-weight: 400;
  letter-spacing: 1px;
  margin: 0 0 20px;
}
@media only screen and (max-width: 768px) {
  .features-area .featured-lists ul li .contents-r h2 {
    font-size: 24px;
  }
}
.features-area .featured-lists ul li .contents-r p {
  color: #85857d;
  margin: 0 0 20px;
}
.features-area .featured-lists ul li .contents-r a {
  font-size: 14px;
  font-weight: 700;
}
.features-area .featured-lists ul li .imgs-r {
  position: relative;
  display: block;
  margin: 0 70px 0 0;
  text-align: right;
}
@media only screen and (max-width: 768px) {
  .features-area .featured-lists ul li .imgs-r {
    margin: 0;
    text-align: center;
  }
}
.features-area .featured-lists ul li .imgs-r img {
  display: inline-block;
}

.steps-area {
  position: relative;
  display: block;
  background: #1a1a1a;
}
.steps-area .steps-heading {
  display: block;
  height: 70px;
  line-height: 70px;
  position: relative;
  z-index: 999;
}
.steps-area .steps-heading h2 {
  margin: 0;
  color: #fff;
  font-size: 40px;
  font-weight: 400;
  line-height: 70px;
}
@media only screen and (max-width: 480px) {
  .steps-area .steps-heading h2 {
    font-size: 30px;
  }
}
.steps-area .steps-heading .right-con {
  position: absolute;
  right: 0;
  top: 0;
}
@media only screen and (max-width: 320px) {
  .steps-area .steps-heading .right-con {
    position: relative;
    margin-right: 0;
    text-align: right;
  }
}
.steps-area .steps-heading .right-con span {
  color: #85857d;
  display: inline-block;
  text-transform: uppercase;
  font-size: 14px;
}
@media only screen and (max-width: 480px) {
  .steps-area .steps-heading .right-con span {
    display: none;
  }
}
.steps-area .steps-heading .right-con a {
  display: inline-block;
  color: #85857d;
  width: 70px;
  height: 70px;
  border: 3px solid #85857d;
  border-radius: 100%;
  line-height: 64px;
  text-align: center;
  font-size: 26px;
  position: relative;
  margin-left: 15px;
}
.steps-area .steps-heading .right-con a:after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  bottom: -103px;
  margin: 0 auto;
  width: 2px;
  background: #85857d;
  z-index: 9;
}
@media only screen and (max-width: 480px) {
  .steps-area .steps-heading .right-con a:after {
    display: none;
  }
}
.steps-area .steps-heading .right-con a:before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  bottom: 0;
  margin: 0 auto;
  width: 2px;
  background:#f25a1d;
  z-index: 99;
  transition: all 0.5s;
}
@media only screen and (max-width: 480px) {
  .steps-area .steps-heading .right-con a:before {
    display: none;
  }
}
.steps-area .steps-heading .right-con a i {
  animation: animate 1s linear infinite;
}
.steps-area .steps-heading .right-con a:hover {
  background: rgba(172, 118, 34);
background: -moz-linear-gradient(left, rgba(172, 118, 34) 0%, rgba(229, 175, 65, 1) 100%);
background: -webkit-gradient(left top, right top, color-stop(0%, rgba(172, 118, 34)), color-stop(100%, rgba(229, 175, 65, 1)));
background: -webkit-linear-gradient(left, rgba(172, 118, 34) 0%, rgba(229, 175, 65, 1) 100%);
background: -o-linear-gradient(left, rgba(172, 118, 34) 0%, rgba(229, 175, 65, 1) 100%);
background: -ms-linear-gradient(left, rgba(172, 118, 34) 0%, rgba(229, 175, 65, 1) 100%);
background: linear-gradient(to right, #f25a1d 0%, #f25a1d 100%);

  border: 3px solid rgb(58, 159, 165);
  color: #fff;
}
.steps-area .steps-heading .right-con a:hover:before {
  bottom: -103px;
}
.steps-area .steps-heading .right-con a:hover i {
  animation: none;
}

@keyframes animate {
  0% {
    transform: translateY(-50%);
    text-shadow: 0 50px 0 #85857d;
  }
  20% {
    transform: translateY(0px);
    text-shadow: 0 10px 0 #85857d;
  }
  40% {
    transform: translateY(0px);
    text-shadow: 0 10px 0 #85857d;
  }
  60% {
    transform: translateY(0px);
    text-shadow: 0 0px 0 #85857d;
  }
  100% {
    transform: translateY(100px);
    text-shadow: 0 -50px 0 #85857d;
  }
}
.steps-details {
  display: block;
  margin: 0 0 -4px;
}
.steps-details .steps-cont ul li {
  position: relative;
  padding: 105px;
  display: block;
  border-radius: 10px;
  margin: 0 0 -2px;
}
@media only screen and (max-width: 480px) {
  .steps-details .steps-cont ul li {
    padding: 80px 50px;
  }
}
.steps-details .steps-cont ul li .mid-icons {
  position: absolute;
  bottom: -14px;
  background: rgba(172, 118, 34);
background: -moz-linear-gradient(left, rgba(172, 118, 34) 0%, rgba(229, 175, 65, 1) 100%);
background: -webkit-gradient(left top, right top, color-stop(0%, rgba(172, 118, 34)), color-stop(100%, rgba(229, 175, 65, 1)));
background: -webkit-linear-gradient(left, rgba(172, 118, 34) 0%, rgba(229, 175, 65, 1) 100%);
background: -o-linear-gradient(left, rgba(172, 118, 34) 0%, rgba(229, 175, 65, 1) 100%);
background: -ms-linear-gradient(left, rgba(172, 118, 34) 0%, rgba(229, 175, 65, 1) 100%);
background: linear-gradient(to right, #f25a1d 0%, #f25a1d 100%);
  left: 0;
  right: 0;
  margin: 0 auto;
  height: 28px;
  width: 28px;
  line-height: 24px;
  border-radius: 100%;
  z-index: 999;
  text-align: center;
}
.steps-details .steps-cont ul li .mid-icons img {
  display: inline-block;
}
.steps-details .steps-cont ul li:before {
  content: '';
  position: absolute;
  left: 48px;
  right: 48px;
  top: 0;
  bottom: 0;
  border-top: 2px solid #f25a1d;
  border-radius: 0;
}
@media only screen and (max-width: 480px){
  .steps-details .steps-cont ul li:before{
    left: 32px;
    right: 38px;
  }
}
.steps-details .steps-cont ul li:after {
  content: '';
  position: absolute;
  width: 14px;
  right: 34px;
  top: 0;
  bottom: 0;
  border-right: 2px solid #f25a1d;
  border-top: 2px solid #f25a1d;
  border-bottom: 2px solid #f25a1d;
  border-radius: 0 10px 10px 0;
}
@media only screen and (max-width: 480px) {
  .steps-details .steps-cont ul li:after {
    right: 24px;
  }
}
.steps-details .steps-cont ul li:first-child:before {
  display: none;
}
.steps-details .steps-cont ul li:first-child:after {
  border-radius: 0 0 10px 0;
  border-top: none;
}
.steps-details .steps-cont ul li.l-con {
  text-align: right;
  position: relative;
}
@media only screen and (max-width: 480px) {
  .steps-details .steps-cont ul li.l-con {
    padding-right: 70px;
    padding-left: 0;
  }
}
.steps-details .steps-cont ul li.l-con .cont h2 {
  margin: 0 0 5px;
  font-weight: 400;
  color: #f25a1d;
}
@media only screen and (max-width: 480px) {
  .steps-details .steps-cont ul li.l-con .cont h2 {
    font-size: 24px;
  }
}
.steps-details .steps-cont ul li.l-con .cont p {
  margin: 0;
}
@media only screen and (max-width: 480px) {
  .steps-details .steps-cont ul li.l-con .cont p {
    font-size: 14px;
  }
}
.steps-details .steps-cont ul li.l-con span {
  position: absolute;
  right: 0;
  width: 70px;
  height: 70px;
  background: rgba(172, 118, 34);
background: -moz-linear-gradient(left, rgba(172, 118, 34) 0%, rgba(229, 175, 65, 1) 100%);
background: -webkit-gradient(left top, right top, color-stop(0%, rgba(172, 118, 34)), color-stop(100%, rgba(229, 175, 65, 1)));
background: -webkit-linear-gradient(left, rgba(172, 118, 34) 0%, rgba(229, 175, 65, 1) 100%);
background: -o-linear-gradient(left, rgba(172, 118, 34) 0%, rgba(229, 175, 65, 1) 100%);
background: -ms-linear-gradient(left, rgba(172, 118, 34) 0%, rgba(229, 175, 65, 1) 100%);
background: linear-gradient(to right, #f25a1d 0%, #f25a1d 100%);
  color: #fff;
  font-size: 30px;
  border-radius: 100%;
  text-align: center;
  top: 39%;
  line-height: 56px;
  z-index: 999;
}
.steps-details .steps-cont ul li span > img {
  width: 40px;
  height: 40px;
  object-fit: scale-down;
  line-height: 40px;
}
@media only screen and (max-width: 480px) {
  .steps-details .steps-cont ul li.l-con span {
    height: 50px;
    width: 50px;
    line-height: 50px;
    font-size: 20px;
  }
}
.steps-details .steps-cont ul li.r-con {
  text-align: left;
  position: relative;
}
@media only screen and (max-width: 480px) {
  .steps-details .steps-cont ul li.r-con {
    padding-left: 70px;
    padding-right: 0;
  }
}
.steps-details .steps-cont ul li.r-con:after {
  left: 34px;
  right: inherit;
  border-left: 2px solid #f25a1d;
  border-right: none;
  border-radius: 10px 0 0 10px;
}
@media only screen and (max-width: 480px) {
  .steps-details .steps-cont ul li.r-con:after {
    left: 24px;
  }
}
.steps-details .steps-cont ul li.r-con:last-child:after {
  border-radius: 10px 0 0 0;
}
.steps-details .steps-cont ul li.r-con .cont h2 {
  margin: 0 0 5px;
  font-weight: 400;
  color: #f25a1d;
}
@media only screen and (max-width: 480px) {
  .steps-details .steps-cont ul li.r-con .cont h2 {
    font-size: 24px;
  }
}
.steps-details .steps-cont ul li.r-con .cont p {
  margin: 0;
}
@media only screen and (max-width: 480px) {
  .steps-details .steps-cont ul li.r-con .cont p {
    font-size: 14px;
  }
}
.steps-details .steps-cont ul li.r-con span {
  position: absolute;
  left: 0;
  width: 70px;
  height: 70px;
  background: rgba(172, 118, 34);
background: -moz-linear-gradient(left, rgba(172, 118, 34) 0%, rgba(229, 175, 65, 1) 100%);
background: -webkit-gradient(left top, right top, color-stop(0%, rgba(172, 118, 34)), color-stop(100%, rgba(229, 175, 65, 1)));
background: -webkit-linear-gradient(left, rgba(172, 118, 34) 0%, rgba(229, 175, 65, 1) 100%);
background: -o-linear-gradient(left, rgba(172, 118, 34) 0%, rgba(229, 175, 65, 1) 100%);
background: -ms-linear-gradient(left, rgba(172, 118, 34) 0%, rgba(229, 175, 65, 1) 100%);
background: linear-gradient(to right, #f25a1d 0%, #f25a1d 100%);
  color: #fff;
  font-size: 30px;
  border-radius: 100%;
  text-align: center;
  top: 39%;
  line-height: 56px;
  z-index: 999;
}
@media only screen and (max-width: 480px) {
  .steps-details .steps-cont ul li.r-con span {
    height: 50px;
    width: 50px;
    line-height: 50px;
    font-size: 20px;
  }
}
.steps-details .steps-cont ul li:nth-child(3):after {
  content: '';
  position: absolute;
  width: 55%;
  right: 34px;
  top: 0;
  bottom: 0;
  border-right: 2px solid #f25a1d;
  border-top: 2px solid #f25a1d;
  border-bottom: 2px solid #f25a1d;
  border-radius: 0 10px 10px 0;
}
.steps-details .steps-cont ul li:nth-child(4):before {
  content: '';
  position: absolute;
  left: 48px;
  right: inherit;
  width: 45%;
  top: 0;
  bottom: 0;
  border-top: 2px solid #85857d;
  border-radius: 0;
}
@media only screen and (max-width: 480px) {
  .steps-details .steps-cont ul li:nth-child(4):before {
    left: 32px;
  }
}
.steps-details .steps-cont ul li:nth-child(4):after {
  border-left: 2px solid #85857d;
  border-top: 2px solid #85857d;
  border-bottom: 2px solid #85857d;
}
.steps-details .steps-cont ul li:nth-child(4) .cont h2 {
  margin: 0 0 5px;
  font-weight: 400;
  color: #f25a1d;
}
.steps-details .steps-cont ul li:nth-child(4) .cont p {
  margin: 0;
}
.steps-details .steps-cont ul li:nth-child(4) span {
  position: absolute;
  right: 0;
  width: 70px;
  height: 70px;
  line-height: 56px;
  border: 2px solid #85857d;
  background: #1a1c35;
  color: #ffffff;
  font-size: 30px;
  border-radius: 100%;
  text-align: center;
  top: 39%;
  z-index: 999;
}
@media only screen and (max-width: 480px) {
  .steps-details .steps-cont ul li:nth-child(4) span {
    height: 50px;
    width: 50px;
    line-height: 46px;
    font-size: 20px;
  }
}
.steps-details .steps-cont ul li:nth-child(5):before {
  border-top: 2px solid #85857d;
}
.steps-details .steps-cont ul li:nth-child(5):after {
  border-right: 2px solid #85857d;
  border-top: 2px solid #85857d;
  border-bottom: 2px solid #85857d;
  border-bottom: 0;
  height: 50%;
}
.steps-details .steps-cont ul li:nth-child(5) .cont h2 {
  margin: 0 0 5px;
  font-weight: 400;
  color: #f25a1d;
}
.steps-details .steps-cont ul li:nth-child(5) .cont p {
  margin: 0;
}
.steps-details .steps-cont ul li:nth-child(5) span {
  line-height: 56px;
  border: 2px solid #85857d;
  background: #000014;
  color: #ffffff;
}
@media only screen and (max-width: 480px) {
  .steps-details .steps-cont ul li:nth-child(5) span {
    line-height: 46px;
  }
}
.steps-details, .video-des{
	background:#000014;
}
.steps-details .steps-cont ul li:nth-child(6):before {
  border-top: 2px solid #85857d;
}
.steps-details .steps-cont ul li:nth-child(6):after {
  border-left: 2px solid #85857d;
  border-top: 2px solid #85857d;
  border-bottom: none;
}
.steps-details .steps-cont ul li:nth-child(6) .cont h2 {
  margin: 0 0 5px;
  font-weight: 400;
  color: #f25a1d;
}
.steps-details .steps-cont ul li:nth-child(6) .cont p {
  margin: 0;
}
.steps-details .steps-cont ul li:nth-child(6) span {
  line-height: 66px;
  border: 2px solid #85857d;
  background: #1a1c35;
  color: #ffffff;
}
@media only screen and (max-width: 480px) {
  .steps-details .steps-cont ul li:nth-child(6) span {
    line-height: 46px;
  }
}

.sud {
  width: 100%;
  display: block;
  overflow: hidden;
  margin: 0 0 -9px;
  background:#1a1c35;
}
@media only screen and (max-width: 992px) {
  .sud {
    display: none;
  }
}

body.date-20180212.exp-invert-logo {
  background-color: transparent !important;
  border-radius: 25px !important;
}

.html5-video-player:not(.ytp-transparent) {
  background: #fff !important;
  border-radius: 25px !important;
}

.video-area {
  position: relative;
  display: block;
  background: #000014;
  padding-bottom: 1px;
  text-align: center;
  margin: 0;
}
.video-area  h2 {
  font-size: 40px;
  color: #fff;
  font-weight: 400;
  margin: 80px 0 60px;
}
.video-area .videos-heding .videos-box {
  display: inline-block;
  margin: 0 0 -175px;
  position: relative;
  z-index: 999;
  border-radius: 25px;
  overflow: hidden;
  vertical-align: middle;
}
.video-area .videos-heding .videos-box:after {
  content: '';
  position: absolute;
  left: 10%;
  right: 10%;
  top: -20px;
  bottom: 0;
  border-radius: 20px;
  background: #e05d5e;
  z-index: 1;
}
.video-area .videos-heding .videos-box .video-js .vjs-control-bar {
  bottom: 20px;
  left: 10%;
  right: 10%;
  width: auto;
}
.video-area .videos-heding .videos-box .video-js {
  background: #fff;
  border-radius: 30px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  z-index: 99;
}
.video-area .videos-heding .videos-box .vjs-poster {
  background: #fff;
  border-radius: 30px;
}
.video-area .videos-heding .videos-box .video-js .vjs-big-play-button {
  width: 125px;
  height: 125px;
  border-radius: 100%;
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  left: 0;
  right: 0;
  top: 32%;
  margin: 0 auto;
}
.video-area .videos-heding .videos-box .video-js .vjs-big-play-button:before {
  content: '';
  position: absolute;
  background: url("../images/icons/vid.png") no-repeat center;
  height: 125px;
  width: 50px;
  line-height: 125px;
  margin: 0 auto;
  left: 0;
  right: 0;
  top: 0;
}
.video-area .videos-heding .videos-box .vjs-icon-play:before {
  display: none;
}

.chart {
  display: block;
}

/*....................................
5. Team area start here
....................................*/
.team-area {
  background: #1a1a1a;
  position: relative;
}
.team-area .team-list {
  display: block;
  position: relative;
  height: auto;
  margin-top: 18px;
  border-radius: 15px;
  background: #1a1c35;
  margin-bottom: 15px;
  width: 100%;
}
.team-area .team-list:after {
  content: '';
  position: absolute;
  left: 50px;
  right: 50px;
  top: -18px;
  bottom: 0;
  height: 100px;
  background: rgba(172, 118, 34);
background: -moz-linear-gradient(left, rgba(172, 118, 34) 0%, rgba(229, 175, 65, 1) 100%);
background: -webkit-gradient(left top, right top, color-stop(0%, rgba(172, 118, 34)), color-stop(100%, rgba(229, 175, 65, 1)));
background: -webkit-linear-gradient(left, rgba(172, 118, 34) 0%, rgba(229, 175, 65, 1) 100%);
background: -o-linear-gradient(left, rgba(172, 118, 34) 0%, rgba(229, 175, 65, 1) 100%);
background: -ms-linear-gradient(left, rgba(172, 118, 34) 0%, rgba(229, 175, 65, 1) 100%);
background: linear-gradient(to right, #f25a1d 0%, #f25a1d 100%);
  border-radius: 15px;
}
.team-area .team-list .conte {
  position: relative;
  z-index: 9999;
  padding: 45px;
  /* padding-top: 45px; */
  background: #000014;
  z-index: 999;
  border-radius: 15px;
}
@media only screen and (max-width: 992px) {
  .team-area .team-list .conte {
    padding: 30px 20px 0 20px;
  }
  .team-area .team-list .conte .con{
    padding: 0 0 25px;
  }
}
.team-area .team-list .dbox {
  margin: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
}
@media only screen and (max-width: 480px) {
  .team-area .team-list .dbox .dleft {
    width: 100%;
    display: block;
    float: inherit;
    margin-bottom: 15px;
  }
}
@media only screen and (max-width: 480px) {
  .team-area .team-list .dbox .dright {
    width: 100%;
    display: block;
  }
}
.team-area .team-list .dbox .content h3 {
  font-size: 23px;
  font-weight: 400;
  color: #ffffff;
  margin: 0;
  margin-bottom: 7px;
}
.team-area .team-list .dbox .content span {
  color: rgb(255 255 255 / 99%);
  display: block;
  margin: 13px 0 6px;
  font-weight: 500;
  line-height: 1;
  font-size: 14px;
}
.team-area .team-list .dbox .content p {
  margin: 0;
  font-size: 14px;
}
.team-area .team-list .dbox .content ul li {
  display: inline-block;
  position: relative;
  padding: 0 5px;
}
@media only screen and (max-width: 992px) {
  .team-area .team-list .dbox .content ul li {
    padding: 0;
  }
}
@media only screen and (max-width: 768px) {
  .team-area .team-list .dbox .content ul li {
    padding: 0 5px;
  }
}
.team-area .team-list .dbox .content ul li:first-child {
  padding-left: 0;
}
.team-area .team-list .dbox .content ul li:last-child {
  padding-right: 0;
}
.team-area .team-list .dbox .content ul li a {
  display: block;
  width: 50px;
  height: 50px;
  line-height: 50px;
  background: rgba(172, 118, 34);
background: -moz-linear-gradient(left, rgba(172, 118, 34) 0%, rgba(229, 175, 65, 1) 100%);
background: -webkit-gradient(left top, right top, color-stop(0%, rgba(172, 118, 34)), color-stop(100%, rgba(229, 175, 65, 1)));
background: -webkit-linear-gradient(left, rgba(172, 118, 34) 0%, rgba(229, 175, 65, 1) 100%);
background: -o-linear-gradient(left, rgba(172, 118, 34) 0%, rgba(229, 175, 65, 1) 100%);
background: -ms-linear-gradient(left, rgba(172, 118, 34) 0%, rgba(229, 175, 65, 1) 100%);
background: linear-gradient(to right, #f25a1d 0%, #f25a1d 100%);
  text-align: center;
  color: #ffffff;
  font-size: 20px;
  border-radius: 100%;
}
@media only screen and (max-width: 992px) {
  .team-area .team-list .dbox .content ul li a {
    width: 40px;
    height: 40px;
    line-height: 34px;
    font-size: 16px;
  }
}
@media only screen and (max-width: 768px) {
  .team-area .team-list .dbox .content ul li a {
    width: 50px;
    height: 50px;
    line-height: 44px;
    font-size: 20px;
  }
}
.team-area .team-list .dbox .content ul li a:hover {
  color: #ffffff;
background: rgba(229, 175, 65, 1);
background: -moz-linear-gradient(left, rgba(229, 175, 65, 1) 0%, rgba(172, 118, 34) 100%);
background: -webkit-gradient(left top, right top, color-stop(0%, rgba(229, 175, 65, 1)), color-stop(100%, rgba(172, 118, 34)));
background: -webkit-linear-gradient(left, rgba(229, 175, 65, 1) 0%, rgba(172, 118, 34) 100%);
background: -o-linear-gradient(left, rgba(229, 175, 65, 1) 0%, rgba(172, 118, 34) 100%);
background: -ms-linear-gradient(left, rgba(229, 175, 65, 1) 0%, rgba(172, 118, 34) 100%);
background: linear-gradient(to right, rgba(229, 175, 65, 1) 0%, rgba(172, 118, 34) 100%);
}
.team-area .team-list .con p {
  padding: 0 20px;
}

.particles-team .row .col-xs-12.col-sm-6.col-md-6.col-lg-6:last-child {
  margin: 0 auto;
  align-items: center;
  float: unset;
  display: flex;
}



@media only screen and (max-width: 992px) {
  .team-area .team-list .con p {
    padding: 0;
  }
}

.team2-area {
  background: #1a1a1a;
  position: relative;
}
.team2-area .team-list {
  display: block;
  position: relative;
  height: 350px;
  margin-top: 18px;
  border-radius: 15px;
  background: #1a1a1a;
  margin-bottom: 50px;
  transition: all 0.5s;
}
.team2-area .team-list:hover {
  transform: translateY(-5px);
}
.team2-area .team-list:after {
  content: '';
  position: absolute;
  left: 50px;
  right: 50px;
  top: -18px;
  bottom: 0;
  height: 100px;
  background: rgba(172, 118, 34);
background: -moz-linear-gradient(left, rgba(172, 118, 34) 0%, rgba(229, 175, 65, 1) 100%);
background: -webkit-gradient(left top, right top, color-stop(0%, rgba(172, 118, 34)), color-stop(100%, rgba(229, 175, 65, 1)));
background: -webkit-linear-gradient(left, rgba(172, 118, 34) 0%, rgba(229, 175, 65, 1) 100%);
background: -o-linear-gradient(left, rgba(172, 118, 34) 0%, rgba(229, 175, 65, 1) 100%);
background: -ms-linear-gradient(left, rgba(172, 118, 34) 0%, rgba(229, 175, 65, 1) 100%);
background: linear-gradient(to right, #f25a1d 0%, #f25a1d 100%);
  border-radius: 15px;
}
.team2-area .team-list .conte {
  position: relative;
  z-index: 9999;
  padding: 0 45px;
  padding-top: 45px;
  background: #1a1a1a;
  z-index: 999;
  border-radius: 15px;
}
@media only screen and (max-width: 992px) {
  .team2-area .team-list .conte {
    padding: 30px 20px 0 20px;
  }
  .team2-area .team-list .conte .con{
    padding: 0 0 25px;
  }
}
.team2-area .team-list .dbox {
  margin: 0 0 25px;
}
@media only screen and (max-width: 480px) {
  .team2-area .team-list .dbox .dleft {
    width: 100%;
    display: block;
    float: inherit;
    margin-bottom: 15px;
  }
}
@media only screen and (max-width: 480px) {
  .team2-area .team-list .dbox .dright {
    width: 100%;
    display: block;
  }
}
.team2-area .team-list .dbox .content h3 {
  font-size: 26px;
  font-weight: 400;
  color: #ffffff;
  margin: 0;
}
.team2-area .team-list .dbox .content span {
  color: rgba(255, 255, 255, 0.61);
  display: block;
  margin: 0 0 20px;
}
.team2-area .team-list .dbox .content ul li {
  display: inline-block;
  position: relative;
  padding: 0 5px;
}
@media only screen and (max-width: 992px) {
  .team2-area .team-list .dbox .content ul li {
    padding: 0;
  }
}
@media only screen and (max-width: 768px) {
  .team2-area .team-list .dbox .content ul li {
    padding: 0 5px;
  }
}
.team2-area .team-list .dbox .content ul li:first-child {
  padding-left: 0;
}
.team2-area .team-list .dbox .content ul li:last-child {
  padding-right: 0;
}
.team2-area .team-list .dbox .content ul li a {
  display: block;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  background: rgba(172, 118, 34);
background: -moz-linear-gradient(left, rgba(172, 118, 34) 0%, rgba(229, 175, 65, 1) 100%);
background: -webkit-gradient(left top, right top, color-stop(0%, rgba(172, 118, 34)), color-stop(100%, rgba(229, 175, 65, 1)));
background: -webkit-linear-gradient(left, rgba(172, 118, 34) 0%, rgba(229, 175, 65, 1) 100%);
background: -o-linear-gradient(left, rgba(172, 118, 34) 0%, rgba(229, 175, 65, 1) 100%);
background: -ms-linear-gradient(left, rgba(172, 118, 34) 0%, rgba(229, 175, 65, 1) 100%);
background: linear-gradient(to right, #f25a1d 0%, #f25a1d 100%);
  color: #ffffff;
  font-size: 20px;
  border-radius: 100%;
}
@media only screen and (max-width: 992px) {
  .team2-area .team-list .dbox .content ul li a {
    width: 40px;
    height: 40px;
    line-height: 34px;
    font-size: 16px;
  }
}
@media only screen and (max-width: 768px) {
  .team2-area .team-list .dbox .content ul li a {
    width: 50px;
    height: 50px;
    line-height: 44px;
    font-size: 20px;
  }
}
.team2-area .team-list .dbox .content ul li a:hover {
  color: #ffffff;
background: rgba(229, 175, 65, 1);
background: -moz-linear-gradient(left, rgba(229, 175, 65, 1) 0%, rgba(172, 118, 34) 100%);
background: -webkit-gradient(left top, right top, color-stop(0%, rgba(229, 175, 65, 1)), color-stop(100%, rgba(172, 118, 34)));
background: -webkit-linear-gradient(left, rgba(229, 175, 65, 1) 0%, rgba(172, 118, 34) 100%);
background: -o-linear-gradient(left, rgba(229, 175, 65, 1) 0%, rgba(172, 118, 34) 100%);
background: -ms-linear-gradient(left, rgba(229, 175, 65, 1) 0%, rgba(172, 118, 34) 100%);
background: linear-gradient(to right, rgba(229, 175, 65, 1) 0%, rgba(172, 118, 34) 100%);
}
.team2-area{
	background:#1a1c35;
}
.team2-area .team-list .con p {
  padding: 0 20px;
}

/*....................................
8. Price list area start here
....................................*/
/*....................................
12. Faq area start here
....................................*/
.slider-area{
	position:relative;
}
canvas{
	position:absolute;
	width:100% !important;
	left:0;
	right:0;
	z-index:10;
}

.slider a.previousButton svg polygon, .slider a.nextButton svg polygon{
  fill: #fff !important;
}
.video-js{
  width: 770px;
  height: 400px;
}











section.page_sec {
  padding: 107px 0 0;
  /* background-color: #1a1c35; */
  position: relative;
  overflow: hidden;
  margin-top: 93px;
}

.no-gutter {
  margin: 0;
}

.no-gutter>*{
  padding: 0 !important;
}



.float-none {
  float: none !important;
}


.otp_group input {
  padding-right: 0px !important;

}

.login-box label.custom-control-label a {
  color: #fff;
  margin: 0 4px;
  font-weight: 500;
}





/* dashboard */


#wrapper {
  display: flex;
  min-height: calc(100vh - 84px);
  position: relative;
  margin-top: 84px;
}
.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.sidebar {
  min-width: 320px;
  min-height: 100vh;
  background: #272a4b;
  overflow: hidden;
  padding: 1rem 0;
}
@media (min-width: 768px){
  .sidebar {
      min-width: 300px!important;
  }
}
.nav-link {
  display: block;
  padding: .6rem 1rem;
}
.sidebar .sidebar-heading {
  padding: 0 1rem;
  font-weight: 600;
  font-size: 16px;
  color: #fff;
  margin-bottom: 0.7rem;
}
.sidebar .nav-item {
  position: relative;
}

.sidebar .nav-item .nav-link {
  text-align: left;
  padding: 1rem;
  color: #ffffffd1;
  font-weight: normal;
  padding: 14px 1rem;
  font-size: 15px;
  transition: 0.2s;
  border-left: 3px solid transparent;
  width: 100%;
}

.sidebar .nav-item .nav-link:hover, .sidebar .nav-item.active .nav-link {
  background: #ffffff0d;
  border-color: #53c5ff;
}

#wrapper #content-wrapper {
  background-color: #000014;
  width: 100%;
  overflow-x: hidden;
  color: #fff;
  transition: 0.2s;
}

.flex-column {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
  -ms-flex-direction: column !important;
  flex-direction: column !important;
}
.d-flex {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}
.justify-content-beetween {
  justify-content: space-between;
}

#content-wrapper .container-fluid {
  padding: 2rem;
  max-width: 1500px;
}

.container-fluid {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}
.col-auto {
  flex: 0 0 auto;
  width: auto;
  padding: 0 15px;
}


.copy_codex {
  position: relative;
}
.copy_icon {
  position: absolute;
  right: 5px;
  top: 38px;
  background: #3e3e3e;
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
}

.copy_icon:hover {
  background: #000;
}



.page-header {
  color: #fff;
  padding-bottom: 0;
  margin: 0;
  border: 0;
}

.page-header-title {
  margin-bottom: 0;
  list-height: 1.4;
}

.container-xl{
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}


@media (min-width: 768px){
  .container-xl {
    max-width: 90%;
  }
}
@media (min-width: 1800px){
  .container-xl {
    max-width: 1440px;
  }
}

.page-header .page-header-content {
  padding-top: 3rem;
  padding-bottom: 3rem;
}




.modal-open .modal, .modal.in .modal-dialog {
  z-index: 9999;
}


.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #1a1a1a;
  background-clip: border-box;
  border-radius: 0.35rem;
  transition: box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: transform 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
  margin-top: 14px;
  z-index: 1;
  /* overflow: hidden; */
  border-radius: 15px;
}
.btn_link {
  color: #f25a1d;
  margin: 0 8px;
  cursor: pointer;
}

.show_more{
  display: none;
}
.show_more.collapsed{
  display: unset;
}
.m-auto {
  margin: 0 auto;
  float: none;
}
.card:before {
  content: "";
  height: 14px;
  width: 90%;
  left: 5%;
  border-radius: 15px 15px 0 0;
  position: absolute;
  top: -14px;
  background: linear-gradient(to right, #f25a1d 0%, #f25a1d 100%);
}
.card-body {
  flex: 1 1 auto;
  padding: 2rem;
}

.tocken_title {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

.tocken_title img {
  width: 50px;
  height: 50px;
  margin-right: 15px;
}

.tocken_title h2 {
  margin: 0;
}
.amount {
  margin: 2rem 0;
}
.amount p {
  margin: 0 0 7px 0;
}

.amount h1 {
	margin: 0 0 0 0;
	font-size: 2.6rem;
}
.token-img-bg {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.token-img-bg > a {
  min-width: fit-content;
  padding: 0 2rem;
}

img.card_corner {
  position: absolute;
  right: 0;
  bottom: 0;
  max-width: 380px;
  max-height: -webkit-fit-content;
  max-height: -moz-fit-content;
  max-height: fit-content;
  width: 100%;
  object-fit: scale-down;
  object-position: bottom right;
  z-index: -1;
  border-radius: 0 0 15px 0;
}


.balance-libra {
  background: #0e2648;
  background: #ffffff1a;
  border-radius: 4px;
  padding: 20px;
  -webkit-backdrop-filter: blur(8px);
  backdrop-filter: blur(8px);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.token-img-bg_left {
  display: flex;
  align-items: center;
  justify-content: center;
}

.token-img-bg_left img {
  display: inline-block;
  max-height: 25px;
  width: auto;
  margin-right: 12px;
}
.token-img-bg_left  h2 {
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  padding-top: 0;
  margin-bottom: 0;
}

.balance-libra h3 {
  font-size: 12px;
  font-weight: 500;
  color: #fff;
  margin: 0;
  padding: 0;
  text-align: right;
}



.qr_code {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
}
.qr_code img {
  height: 280px;
  width: 281px;
  object-fit: scale-down;
  border-radius: 15px;
  background-color: #fff;
}
.qr_code  .qr_content{
  padding-left: 20px;
  width: calc(100% - 300px);
}
.copy_codex input {
  padding-right: 45px !important;
}
.qr_content .balance-libra {
  background: transparent;
  border-bottom: 1px solid #c28c2e;
  border-radius: 0;
  padding: 20px 0;
}
.spinnerContainernew{
  background: #fff !important;
}
.qr_code h5 {
  display: block;
  text-align: right;
  margin: 4rem 0 2rem;
  font-size: 1.2rem;
  font-weight: 400;
}

p.errorMessage {
  font-size: 12px;
  margin-bottom: 16px;
  margin-top: -13px;
  color: #ff6969;
}
.copy_input input {
  background: #1a1a1a;
  border: solid 1px #ffffff47;
  padding: 12px 15px;
  color: #ffffff;
  margin-top: 0;
  margin-bottom: 15px;
  width: 100%;
  border-radius: 5px;
  height: auto;
}
.copy_input button {
    position: absolute;
    right: 8px;
    bottom: 5px;
    text-align: right;
    display: inline-block;
    background: #ffffff1f;
    padding: 10px 15px;
    margin-top: 0px;
    font-size: 14px;
    border-radius: 44px;
    font-weight: 600;
    color: #fff;
    border: none;
    line-height: 1;
    margin-bottom: 19px;
    display: block;
    border-radius: 5px;
}
.copy_input {
  position: relative;
}


.card-header {
  padding: 1.1rem 1.35rem;
  margin-bottom: 0;
  background-color: rgb(49 49 49 / 42%);
  border-bottom: 1px solid rgb(204 150 51 / 25%);
  border-radius: 0.35rem 0.35rem 0 0;
  font-weight: 600;
}


.rounded-circle {
  border-radius: 50% !important;
}

.img-account-profile {
  height: 10rem;
}
.mb-2 {
  margin-bottom: 0.5rem !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: #a7aeb8 !important;
}
.btn-mini {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background-color: #0061f2;
  border-color: #0061f2;
}



.form-control{
    background: #1a1a1a;
    border: solid 1px #ffffff47;
    padding: 12px 15px;
    color: #ffffff;
    margin-top: 0;
    margin-bottom: 15px;
    width: 100%;
    border-radius: 5px;
    height: auto;
}
.err_msg {
  border-color: red !important;
}
.form-control:focus {
  border-color: #2fb8ca;
  box-shadow: none !important;
  outline: 0 !important;
}
.otp_group {
  position: relative;
}
#getotp {
  text-align: right;
  display: inline-block;
  background: #af7923;
  padding: 10px 15px;
  margin-top: 0px;
  font-size: 14px;
  border-radius: 44px;
  font-weight: 600;
  color: #fff;
  border: none;
  line-height: 1;
  margin-bottom: 19px;
  display: block;
  border-radius: 5px;
}
#getotp:hover {
  background: linear-gradient(to right, #f25a1d 0%, #f25a1d 100%) !important;
}




.what {
  margin-top: 1.5rem;
  text-align: left;
}

.whh {
  display: block;
  font-size: 14px;
  font-weight: 300;
}












.otp_group #getotp {
  position: absolute;
  right: 6px;
  bottom: -13px;
}
form label {
  font-weight: 500;
  color: #85857d;
  letter-spacing: .2px;
}

.file_upload {
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.file_upload > input {
  width: 100%;
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  opacity: 0;
  cursor: pointer;
}

.card-success {
  background: #3fffae1a;
  padding: 12px 12px;
}
.card-success * {
  color: #49e9a6 !important;
  font-weight: 600 !important;
}
.text-danger {
  color: #ff6f64;
}




.nav-tabs {
  border: none;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}


.nav-tabs .nav-link {
  border: none;
  padding: 10px 30px;
  background: #ffffff29;
  color: #fff;
  margin-right: 5px;
  margin-bottom: 0;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
  cursor: pointer;
  outline: 0 !important;
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active, .nav-tabs .nav-link.react-tabs__tab--selected {
  border: none;
  color: #fff;
  background: #242424;
}





.transaction-history {
  padding: 0;
  width: 100%;
  overflow-x: auto;
  max-height: 480px;
}
.transaction-history table {
  color: #fff;
  padding: 0;
  margin: 0;
  font-weight: 400;
  border: none;
  width: 100%;
  overflow: hidden;
  border-radius: 0;
  background: transparent;
  margin: 0 0;
}
.transaction-history table tr:nth-child(2n+1) {
  background: #ffffff0d;
}
.transaction-history table tr:nth-child(2n+2) {
  background: #2c2c2c;
}
.transaction-history table th {
  color: #fff;
  font-size: 14px;
  white-space: nowrap;
  padding: 10px 15px;
  font-weight: 500;
  background: #000;
  border: none;
}
.transaction-history table th {
  color: #fff;
  font-size: 14px;
  white-space: nowrap;
  padding: 15px 15px !important;
  font-weight: 500;
  background: #242424;
  border: none;
}
.transaction-history table td {
  color: #fff;
  font-size: 13px;
  padding: 15px 15px !important;
  font-weight: 400;
  line-height: 18px !important;
  vertical-align: top;
  border: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.table>thead>tr>th{
  border: 0;
}


.view_pass {
  position: relative;
}
.table>tbody>tr>td, .table>tbody>tr>th, .table>tfoot>tr>td, .table>tfoot>tr>th, .table>thead>tr>td, .table>thead>tr>th {
  border-top: 0;
}

.view_pass .icon_input {
  position: absolute;
  top: 58px;
  right: 15px;
  cursor: pointer;
}
.view_pass .icon_input:hover {
  color: #ffffff7a;
}
.view_pass .icon_input.show {
  color: #f25a1d;
}
section.page_sec.blog_lists {
  background: #1a1a1a;
  padding: 0 0 75px;
  margin-top: 93px;
}

.blog_lists h1.page-header-title {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control {
  background-color: #ffffff1a;
  border-color: #313131;
}


.subscribe_sec {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 80px;
  padding-bottom: 80px;
  border-bottom: 1px solid #ffffff24;
}
.subscribe_sec h2{
  margin: 0  !important;
}

.subscribe_sec .input-group {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border: 1px solid #f25a1d;
  padding: 6px;
  border-radius: 50px;
  overflow: hidden;
}
.subscribe_sec .input-group .form-control {
  border: 0;
  font-size: 16px;
  padding: 0 0 0 12px;
  min-width: 250px;
}
.subscribe_sec .input-group .btn1 {
  border: 0;
  outline: 0;
  color: #fff !important;
  padding: 0;
}
.slider-area .carousel:before {
  content: "";
  background: #1c1100;
  background: linear-gradient(180deg, rgb(0 0 0 / 74%) 0%, #000014c4 78%, #000014 100%);
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: -2px;
}
.slider-area video {
  width: 100%;
  height: 100% !important;
  position: absolute;
  object-fit: cover;
  opacity: 1;
}
.btn1:focus {
  color: #fff !important;
}
img.login_bg {
  position: absolute;
  width: 100%;
  background-size: cover;
  background-position: center;
  opacity: 0.3;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}


.owl-dots {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 0 0;
}


.owl-dots span {
  height: 4px;
  width: 18px;
  border-radius: 2px;
  background: #1a1c35;
  display: block;
  margin: 0 3px;
  transition: 0.3s;
}

.owl-dots .owl-dot.active span {
  background: #f25a1d;
}
.ad_slide {
  padding: 30px;
}
.ad_slide * {
  border-radius: 15px;
  box-shadow: -6px 11px 12px 0px #00001438;
}



.login-btn.login_mobile {
  display: block;
  /* align-items: center; */
  /* justify-content: center; */
  /* padding: 15px; */
  /* margin: 0 -10px; */
  /* position: absolute; */
  /* bottom: 15px; */
} 


.ss_menu_item a {
  padding: 16px 22px;
  /* font-size: 1.1em; */
  display: block;
  color: #fff;
  font-size: 18px;
  font-weight: 600;
}

.ss_menu_item a[aria-expanded="false"]::before, .ss_menu_item a[aria-expanded="true"]::before {
  content: '\e259';
  display: block;
  position: absolute;
  right: 20px;
  font-family: 'Glyphicons Halflings';
  font-size: 0.6em;
}
.ss_menu_item a[aria-expanded="true"]::before {
  content: '\e260';
}




.login-btn.login_mobile > * {
  /* margin: 0 5px; */
}

.pro-sidebar {
	min-height: 100vh !important;
}
.pro-sidebar > .pro-sidebar-inner {
  background: #1a1a1a !important;
}

.pro-sidebar > .pro-sidebar-inner > .pro-sidebar-layout {
	height: 100vh !important;
}
.pro-sidebar .pro-menu a.nav-link {
	display: block;
	padding: .8rem 20px !important;
}

.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item {
	padding: 0 !important;
}

.pro-sidebar .pro-menu > ul > .pro-sub-menu > .pro-inner-list-item {
	background-color: transparent !important;
}


.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item > .pro-item-content > a {
  display: block;
  padding: 7px 15px;
}
.pro-sidebar .pro-menu a:hover::before {
  background-color: #00001414;
}

.pro-sidebar .pro-menu .pro-menu-item.pro-sub-menu .pro-inner-list-item  {
  padding-left: 0 !important;
}



.img-account-profile.rounded-circle {
  width: 160px;
  height: 160px;
}

.qr_code.qr_code_block {
  flex-direction: column;
}
.qr_code.qr_code_block {
  flex-direction: column;
}


.m-auto{
  margin: 0 auto !important;
}
.qr_code.qr_code_block .qr_content {
  width: 100%;
  margin-top: 2rem;
  margin-bottom: 0 !important;
}


.overflow-hidden {
  overflow: hidden !important;
}
.modal-content {
  border: 0;
  border-radius: 17px;
}

.modal-header {
  padding: 22px 24px;
  border-bottom: 1px solid #e5e5e5;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.modal-header:before, .modal-header:after{
  display: none;
}
.modal-header * {
  float: none !important;
}
.modal-header .close{
  margin: 0 !important;
    font-size: 37px;
    font-weight: 700;
    line-height: 1;
    color: #818181;
    text-shadow:none !important;
    opacity: 1;
    font-weight: 300;
    line-height: 37px;
}
.modal-body {
  padding: 22px;
}
.modal-footer {
  padding: 22px;
}
.btn{
  border: 0 !important;
}
.btn-default {
  background: #f5f5f5;
}
.btn-primary {
  color: #fff;
  background-color: #337ab7;
  border-color: #2e6da4;
  background: linear-gradient(to right, #f25a1d 0%, #f25a1d 100%);
}

.btn {
  border: 0 !important;
  border-radius: 50px;
  padding: 10px 30px;
  min-width: 120px;
}



.animated  {
  -webkit-animation-duration : 1000 ms  ;
  animation-duration : 1000 ms  ;
  -webkit-animation-fill-mode : both  ;
  animation-fill-mode : both  ;
}  




.tokes-chart-slider .owl-carousel .owl-stage-outer {
  background: #1a1a1a;
  
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
}


.header_login {
  background: #ffffff !important;
}



.react-tabs__tab-list {
	display: flex;
	align-content: center;
	justify-content: center;
	margin-bottom: 2rem;
  overflow: hidden;
  border-radius: 5px;
}

.react-tabs__tab-list li {
	padding: 10px 22px;
	border: none;
	color: #fff;
	background: #fbfbfb0a;
	width: 100%;
	text-align: center;
	cursor: pointer;
	border-radius: 5px;
	outline: 0;
}

.react-tabs__tab-list li.react-tabs__tab--selected {
  background: linear-gradient(to right, #f25a1d 0%, #f25a1d 100%);
}


.justify-center{
  justify-content: center;
}
.qr_code.qr_code_block .qr_content.pd-0 {
	padding: 0 !important;
}

.btn-icon {
  padding: 10px 0;
  justify-content: center;
  overflow: hidden;
  border-radius: 100%;
  flex-shrink: 0;
  height: 44px !important;
  width: 44px !important;
  min-width: 44px !important;
  background: #1a1c3514;
  color: #000 !important;
  outline: 0 !important;
  margin: 12px 18px 0 0;
}
#wrapper.sidenav-toggled .pro-sidebar {
  transform: translateX(-270px);
}
#wrapper.sidenav-toggled #content-wrapper {
  margin-left: -270px;
}

.profile_bar .img-account-profile.rounded-circle {
	width: 42px;
	height: 42px;
	object-fit: cover;
	border: 2px solid #fff3;
}
.profile_bar span {
	margin-left: 14px;
}
.profile_bar {
	padding: 4px 15px;
	color: #fff;
	display: block;
}

.token-img-bg_right h1 {
	margin-bottom: 0;
	padding: 17px 0 0;
	text-align: right;
	font-size: 5rem;
}

.align-items-center {
	align-content: center;
}
.justify-content-between {
	justify-content: space-between;
}
.text-left{
  text-align: left;
}

.text-right{
  text-align: right;
}
.w-100{
  width: 100%;
}
.w-50{
  width: 50%;
}
.copy_code {
	text-align: right;
	margin-top: -2rem;
}


.react-responsive-modal-root img{
  border-radius: 10px;
}
.d-none {
  display: none !important;
}

.genrate_sec {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6% 0;
}


.gen_btn {
  border-radius: 6px !important;
  background-color: #ffffff2e;
  font-size: 16px;
  color: #ffffff8c;
  font-weight: 300;
}
.gen_btn:hover{
  color: #fff;
}
.react-responsive-modal-root {
  z-index: 9999 !important;
}

.react-responsive-modal-modal {
  background: transparent !important;
  box-shadow: none !important;
  padding: 0 !important;
  max-width: 600px !important;
}
button.react-responsive-modal-closeButton svg {
  fill: #fff !important;
}

.react-responsive-modal-container {
  overflow-x: unset !important;
  overflow-y: unset !important;
}

.react-responsive-modal-overlay {
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(5px);
}

.react-responsive-modal-modal {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  margin: 0 auto !important;
  height: 100% !important;
}


.react-responsive-modal-closeButton {
  position: fixed !important;
  top: 24px !important;
  right: 24px !important;
}


@media (max-width:767px){
  .react-responsive-modal-modal {
    width: 90% !important;
}
}







.radio_box {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  margin: 15px -10px 0;
}
.radio_box label {
  display: flex;
  cursor: pointer;
  font-weight: 500;
  position: relative;
  overflow: hidden;
  /* margin-bottom: 0.375em; */
  margin-right: 0;
  min-width: 50%;
  flex: 0 0 auto;
  padding: 0 10px;
}
.radio_box label input {
  position: absolute;
  left: -9999px;
}
.radio_box label input:checked + span {
  background-color: #f25a1d;
}
.radio_box label input:checked + span:before {
  box-shadow: inset 0 0 0 0.4375em #020257;
  background: #fff;
}
.radio_box label span {
  display: flex;
  align-items: center;
  padding: 0.575em 1em 0.575em 0.675em;
  border-radius: 99em;
  transition: 0.25s ease;
  min-width: 100px;
  width: 100%;
  text-align: center;
  align-items: center;
  /* justify-content: center; */
  background: #ffffff14;
}
.radio_box label span:hover {
  background-color: #ffffff26;
}
.radio_box label span:before {
  display: flex;
  flex-shrink: 0;
  content: "";
  background-color: #fff0;
  width: 1.5em;
  height: 1.5em;
  border-radius: 50%;
  margin-right: 1rem;
  transition: 0.25s ease;
  box-shadow: inset 0 0 0 0.125em #afafaf;
}
.radio_box label span em {
  font-style: normal;
  font-weight: 600;
  display: flex;
  width: 100%;
  justify-content: center;
  margin-left: -24px;
}




/* faq css */


.faq_sidebar {
  grid-template-columns: unset;
  gap: 0;
  /* margin: 0; */
  text-align: left;
}
.grid.faq_sidebar a {
  flex-direction: row;
  align-items: center;
  border-radius: 8px;
  margin-bottom: 1rem;
  text-align: left;
  padding: 20px 10px 20px 20px;
  color: #fff;
  display: flex;
  background: #333;
  align-items: center;
  box-shadow: 0 0 0 1px #333;
  transition: 0.1s;
}

.grid.faq_sidebar a:hover {
  background: #1e1e1e;
  box-shadow: 0 0 0 1px #333;
}
.grid.faq_sidebar a.active {
  background: #f25a1d;
}
.grid.faq_sidebar a .doc_icon {
  align-items: center;
  box-shadow: none;
  margin: 0;
  margin-right: 20px;
  width: auto;
  height: auto;
  line-height: unset;
}
.grid.faq_sidebar a img {
  width: 25px;
  height: 25px;
}

.grid.faq_sidebar a h5 {
  flex-direction: row;
  align-items: center;
  font-size: 15px;
  font-weight: 400;
  line-height: 1.4;
  margin: 0 !important;
}


.faq {
  border: 1px solid #333;
  background: #333;
  box-shadow: none;
  border-radius: 10px !important;
  position: relative;
  margin-top: 14px;
}
.faq .card {
  border: none;
  background: none;
  border-bottom: 1px dashed #f3f8ff3d;
  border-radius: 0 !important;
  padding: 0;
  margin: 0 !important;
}
.faq .card:before{
  display: none;
}

.faq:before{
  content: "";
    height: 14px;
    width: 90%;
    left: 5%;
    border-radius: 15px 15px 0 0;
    position: absolute;
    top: -14px;
    background: linear-gradient(to right, #f25a1d 0%, #f25a1d 100%);
}
.faq .card .card-header {
  padding: 0px;
  border: none;
  background: none;
  -webkit-transition: all 0.3s ease 0s;
  -moz-transition: all 0.3s ease 0s;
  -o-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  border-radius: 0 !important;
}
.faq .card .card-header .faq-title{
  margin: 0;
}
.faq .card .card-header .faq-title a {
  width: 100%;
  text-align: left;
  padding: 0px;
  padding-left: 40px;
  padding-right: 40px;
  font-weight: 400;
  font-size: 18px;
  letter-spacing: normal;
  color: #fff;
  text-decoration: none !important;
  transition: all 0.3s ease 0s;
  cursor: pointer;
  padding-top: 25px;
  margin: 0;
  padding-bottom: 25px;
  display: flex;
  align-items: center;
}
.faq .card .card-body {
  padding: 30px;
  padding-left: 35px;
  /* padding-bottom: 16px; */
  font-size: 16px;
  color: #6F8BA4;
  /* line-height: 28px; */
  border-top: 1px solid #f3f8ff3d;
  text-align: left;
  color: #ffff;
}

.faq .card .card-header .faq-title a.collapsed:after {
  content: '+';
  position: absolute;
  right: 24px;
}
.faq .card .card-header .faq-title a:after {
  content: '-';
  position: absolute;
  right: 24px;
}
.faq .card:last-child {
  border: 0;
}
.div_body a {
  color: #00c3ff;
  font-weight: 500;
  text-decoration: underline;
}
.panel-body {
  padding: 0;
}



a.claim_free_btn {
  font-size: 17px;
  height: auto !important;
  line-height: unset;
  padding: 0;
  letter-spacing: 0.5px;
  position: fixed;
  top: 125px;
  right: 30px;
  z-index: 10;
  /* background: linear-gradient(to right, #f25a1d 0%, #f25a1d 100%); */
  /* color: #fff; */
  /* border-radius: 50px; */
  transform: translateX(0);
  -webkit-animation: power1 1.5s infinite;
  max-width: 120px;
}
@-webkit-keyframes power1 {
  50% {
    transform: translateX(-2px);
  }
  70% {
    transform: translateX(-20px);
  }
  100% {
    transform: translateX(0);
  }
}



@media (max-width:767px){


  a.claim_free_btn {
    top: auto;
    bottom: 20px;
    right: 20px;
    max-width: 80px;
  }

  @-webkit-keyframes power1 {
    50% {
      transform: translateY(-2px);
    }
    70% {
      transform: translateY(-20px);
    }
    100% {
      transform: translateY(0);
    }
  }
}



/* select metamask modal */
.ioaUFT {
  margin: 0px 0px 2rem;
  background-color: rgb(255 255 255);
  /* box-shadow: rgb(0 0 0 / 5%) 0px 4px 8px 0px; */
  padding: 0px;
  width: 50vw;
  overflow: hidden;
  align-self: center;
  max-width: 420px;
  max-height: 90vh;
  display: flex;
  border-radius: 10px;
  margin: 0!important;
  /* border: 1px solid #000000; */
}
.htmauo {
  display: flex;
  flex-flow: column nowrap;
  margin: 0px;
  padding: 0px;
  width: 100%;
}
.jrlxIo {
  position: relative;
}
.iYbuoI {
  position: absolute;
  right: 1rem;
  top: 14px;
}
.gutdp {
  display: flex;
  flex-flow: row nowrap;
  padding: 1rem;
  font-weight: 500;
  color: inherit;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin-bottom: 0.5rem;
}
.gutdp p {
  margin: 0;
  line-height: 1.4;
  font-size: 15px;
  color: #333;
}
.sc-dqBHgY.dXAyMA {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 0.4rem;
  color: #000;
}
.kxgAIA {
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
.hsKMjF {
  display: grid;
  grid-gap: 16px;
  /* gap: 10px; */
  padding: 2px;
}
.licBat {
  transition: 0.1s;
  outline: 2px solid #3333 !important;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 1rem;
  background: #fff;
  margin-top: 0px;
  /* opacity: 1; */
  width: 100% !important;
  position: relative;
}
.irnmqJ {
  display: flex;
  /* flex-flow: column nowrap; */
  justify-content: center;
  height: 100%;
  align-items: center;
}
.fFNbAV {
  display: flex;
  flex-flow: row nowrap;
  color: rgb(0 0 0);
  font-size: 1rem;
  font-weight: 500;
}

.licBat i {
    color: #00000080;
    font-size: 160%;
}

.hKWpfm {
  display: flex;
  flex-flow: column nowrap;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
}
.hKWpfm > img, .hKWpfm span {
  height: 30px;
  width: 30px;
}
.hPxPTd {
  display: flex;
  flex-flow: row wrap;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  margin-top: 2rem;
}
.hPxPTd a {
  color: #f25a1d;
}
.w-full {
  width: 100%!important;
}
.licBat:hover {
  cursor: pointer;
  /* background: #f5f5f5; */
  /* outline: 2px solid #0000006b !important; */
}
.licBat:hover i{
  color: #000 !important;
}
.sc-tilXH.hKWpfm .mets_big {
  width: 65px;
  height: 65px;
  object-fit: scale-down;
  margin: 1rem;
}
.gutdp .btn-link {
  margin-top: 1rem;
  font-size: 17px;
  color: #004eff;
  display: flex;
  align-items: center;
  font-weight: 600;
}
