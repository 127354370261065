/* Extra Large Devices, Wide Screens */
@media only screen and (max-width: 1600px) {
}
/* Large Devices, Wide Screens */
@media only screen and (max-width: 1200px) {
}

/* Medium Devices, Desktops */
@media only screen and (max-width: 992px) {
  .video-js {
    width: 600px;
    height: 330px;
  }
  .tokens-area .tokens {
    margin: 0;
  }
  .tokens-area .tokens .token-body {
    padding: 50px 25px;
  }
  footer .footer-bottom {
    height: auto;
    line-height: 26px;
    padding: 50px 0;
    text-align: center;
  }
  footer .footer-bottom .foo-link {
    text-align: center;
    margin-top: 15px;
  }
  .logo-area {
    text-align: center;
  }
  header .menu-area {
    float: none;
    width: 100%;
    text-align: center;
  }
}

/* Small Devices, Tablets */
@media only screen and (max-width: 768px) {
  .slider {
    height: unset;
  }
  .video-js {
    width: 480px;
    height: 249px;
  }
  .mobile-menu-area {
    display: block !important;
  }
  .my-video-dimensions {
    width: 480px;
    height: 249px;
  }
  .video-area .videos-heding .videos-box .video-js .vjs-big-play-button {
    top: 24%;
  }
  .tokens-area .tokens {
    display: block;
    margin: 0 0 50px;
  }
  .tokens-area .tokens .token-body {
    display: block;
  }
  .tokens-area .token-con {
    margin: 0;
  }
  .e_chart_3 div {
    margin: 0 auto;
  }
  .token-details .cart-list .label-chatrs {
    padding: 62px 100px;
  }
  .token-details .owl-controls .owl-buttons div.owl-prev {
    left: 0;
    border-radius: 0 15px 15px 0;
  }
  .token-details .owl-controls .owl-buttons div.owl-next {
    right: 0;
    border-radius: 15px 0 0 15px;
  }
  .blog-area .owl-controls .owl-buttons {
    display: none;
  }
  header .mobilemenu .mean-container .mean-nav {
    margin-top: 38px;
  }
  .video-des .deslidt ul li .serials:before,
  .video-des .deslidt ul li .serials span.cre:before,
  .video-des .deslidt ul li .serials span.cre:after {
    display: none;
  }
  #clockdiv > div,
  #clockdiv2 > div {
    margin-bottom: 20px;
  }
  .slider a.previousButton,
  .slider a.nextButton {
    display: none;
  }
  .easypiechart {
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .team-area .team-list {
    height: auto;
  }
  .team2-area .team-list {
    height: auto;
  }
  .slider-area .carousel {
    padding: 30vh 0;
  }
}

/* Extra Small Devices, Phones */
@media only screen and (max-width: 480px) {
  .video-js {
    width: 400px;
    height: 207px;
  }
  .my-video-dimensions {
    width: 400px;
    height: 207px;
  }
  .video-area .videos-heding .videos-box .video-js .vjs-big-play-button {
    top: 24%;
  }
  .video-area .videos-heding h2 {
    font-size: 36px;
  }
  footer .footer-top .con ul li a {
    width: 65px;
    height: 65px;
    line-height: 59px;
    font-size: 30px;
  }
  footer .footer-top .con ul li {
    padding: 0 5px;
  }
  .media-area .mediabrand ul {
    margin: 0;
  }
  .e_chart_3 {
    height: 300px !important;
  }
  .token-details .owl-controls .owl-buttons div {
    display: none;
  }
  .media-area .mediabrand ul li {
    display: block;
    text-align: center;
    padding: 15px 0;
  }
  .team-area .team-list {
    margin-bottom: 50px !important;
    height: auto !important;
    text-align: center;
  }
  .team-area2 .team-list {
    overflow: hidden;
  }
  .team2-area .team-list {
    margin-bottom: 50px !important;
    height: auto !important;
    text-align: center;
  }
  .btc_timer_section_wrapper {
    padding-top: 60px !important;
  }
  .slider-area .slider-content ul li {
    padding: 0 2px;
  }
  .slider-area .slider-content ul li a {
    width: 40px;
    height: 40px;
    line-height: 34px;
    margin: 0 3px;
}
  footer .footer-top .con h2 {
    margin: 0 0 50px;
  }
  .token-details .cart-list .label-chatrs {
    padding: 50px;
  }
  .slider-area .carousel {
    padding: calc(9vh + 72px) 0 9vh;
}

  .slider-area .slider-content {
    padding: 0;
}
.slider-area .carousel:before{
  top: 72px;
}
div#about {
  margin-top: 0 !important;
}
.about-area {
  padding: 0 0 40px;
}
.section {
  padding: 40px 0;
}

}

/* Custom, iPhone Retina */
@media only screen and (max-width: 375px) {
  .video-js {
    width: 320px;
    height: 182px;
  }
  .steps-area .steps-heading .right-con a {
    display: none;
  }
  .steps-area {
    text-align: center;
    padding: 40px 0;
  }

}
@media only screen and (max-width: 320px) {
  .video-js {
    width: 300px;
    height: 155px;
  }
  .my-video-dimensions {
    width: 300px;
    height: 155px;
  }
  .video-area .videos-heding .videos-box .video-js .vjs-big-play-button {
    top: 24%;
  }
  .video-area .videos-heding .videos-box .video-js .vjs-big-play-button {
    width: 70px;
    height: 70px;
  }
  .video-area
    .videos-heding
    .videos-box
    .video-js
    .vjs-big-play-button::before {
    height: 70px;
    line-height: 70px;
    width: 30px;
    background-size: contain;
  }
  .video-area .videos-heding h2 {
    font-size: 30px;
  }
  .tokens-area .tokens .token-body .left-btn {
    padding: 0 20px;
    font-size: 16px;
  }
  .tokens-area .tokens .token-body .right-btn {
    padding: 0 20px;
    font-size: 16px;
  }
  .token-details .cart-list .label-chatrs {
    padding: 30px 25px;
  }
  .team-area .team-list {
    height: auto;
    padding-bottom: 30px;
  }
  .team2-area .team-list {
    height: auto;
    padding-bottom: 30px;
  }
  .steps-area .steps-heading h2 {
    font-size: 24px;
  }
  .video-des .deslidt ul li .left-con .box-con .dbox,
  .video-des .deslidt ul li .right-con .box-con .dbox,
  .video-des .deslidt ul li .center-con .box-con .dbox {
    padding: 30px !important;
  }
}
@media (max-width: 1199px) {
  header .menu-area nav ul li a {
    font-size: 12px;
  }
  .btn1, .ss_menu_item.dropdown .dropdown-toggle {
      height: 40px;
      line-height: 40px;
      padding: 0 25px;
      font-size: 15px;
  }
  .btn2 {
    height: 40px;
    line-height: 40px;
    padding: 0 25px;
  }
  .btn3 {
    height: 40px;
    line-height: 40px;
    padding: 0 25px;
  }
  header .menu-area {
    padding-top: 5px;
  }
  header .menu-area nav ul li {
    padding: 0 9px;
  }
  #clockdiv div > span,
  #clockdiv2 div > span {
    width: 100px;
    height: 189px;
    line-height: 182px;
  }
  .btc_timer_section_wrapper {
    padding-top: 90px;
  }
  .slider-area .slider-content h2 {
    font-size: 40px;
  }
}


@media only screen and (max-width: 767px) {

  header {
    padding-top: 4px;
    padding-bottom: 4px;
}

}


@media (max-width: 767px) {
  .logo-area {
    text-align: left;
  }
  .logo-area {
    width: 180px !important;
  }
  .steps-area .steps-heading .right-con span {
    display: none;
  }
  .tokens-area .tokens .token-body {
    height: auto;
  }
  .media-area .mediabrand ul {
    text-align: center;
  }



  div#about {
    margin-top: 80px;
  }

  .slider-area .slider-content h2 {
    font-size: 27px;
    line-height: 1.4;
    max-width: 345px;
    margin-left: auto;
    margin-right: auto;
  }
  .buttons {
    justify-content: center;
    margin-top: 1.8rem;
  }
  .currency-area .rete-list {
    margin-top: 0;
  }
  .f-40 {
    font-size: 29px;
  }
  .currency-area .rete-list h2 img {
    width: 44px;
    height: 44px;
    object-fit: scale-down;
  }

  .about-area .about-content h5 {
    font-size: 1rem;
    letter-spacing: 3px;
    font-weight: 600;
    margin-top: 1rem;
    border-top: 0;
    padding-top: 0;
    margin: 0 auto 2rem;
  }

  .features-area .featured-lists ul li .contents-l {
    text-align: center;
    margin-right: 0;
  }
  .features-area .featured-lists ul li .imgs-l img {
    margin-bottom: 2rem;
  }

  .video-des .deslidt ul li .left-con .box-con .dbox,
  .video-des .deslidt ul li .right-con .box-con .dbox,
  .video-des .deslidt ul li .center-con .box-con .dbox {
    height: auto;
  }

  .tokens-area .tokens .token-body{
    min-height: auto;
  }

  .tokens-area .tokens {
    margin: 0 0 30px;
  }
  .token-con a.btn1 {
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
  }


  .token-details .cart-list {
    padding: 15px;
    margin: 0;
    flex-direction: column;
  }
  .token-details .cart-list .label-chatrs {
    padding: 22px 0 0;
  }

  .token-details .contents h2 {
    margin: 18px 0 0;
  }

  .team-area .team-list .conte {
    padding: 30px 20px 20px 20px;
  }

  .blog-area {
    padding-top: 0;
  }

  .subscribe_sec {
    margin-bottom: 40px;
    padding-bottom: 40px;
    flex-direction: column;
  }


  .subscribe_sec > h2 {
    margin-bottom: 30px !important;
    font-size: 30px !important;
  }

  .subscribe_sec .input-group .form-control {
    min-width: 200px;
  }
  footer .footer-top .con ul li a {
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 21px;
    margin: 0 1px;
  }

  footer .footer-bottom {
    line-height: 26px;
    padding: 30px 0 15px;
  }

  footer .footer-bottom .foo-link ul li a {
    line-height: 21px;
    font-size: 12px;
  }

  footer .footer-bottom .foo-link ul li {
    padding: 0 14px;
  }


  .ad_slide {
    padding: 0;
    margin-bottom: 1rem;
  }

  section.video-area.section {
    padding: 0;
}
section.projects.bg-img.pd-t100.pd-b70 {
  padding-bottom: 0 !important;
}
.video-des .deslidt ul li:first-child {
  padding-top: 0;
}
.video-des .deslidt ul li .serials {
  margin: 0 0 0;
}

footer .footer-bottom .copyright p {
  line-height: 1.4;
  text-align: left;
}
.token-details .owl-nav div.owl-next {
  right: 14px;
  width: 0;
}

.token-details .owl-nav div.owl-prev {
  left: 0;
  width: 0;
}

.video-des .deslidt ul li:last-child {
  padding-bottom: 20px;
  margin-bottom: 0;
}
.subscribe_sec > h2 {
  font-size: 24px !important;
}
h1 {
  font-size: 28px;
  line-height: 1.5;
}




}
@media (max-width: 1199px) and (min-width: 991px) {
  .rete-list {
    width: 23% !important;
    margin-left: 15px !important;
  }
}


@media (max-width: 1439px) {

  .container-xl {
    max-width: 100%;
    padding: 0 1.5rem;
  }



  #wrapper .pro-sidebar {
    /* transform: translateX(-270px); */
  }
  #wrapper #content-wrapper {
    /* margin-left: -270px; */
  }

  #wrapper.sidenav-toggled .pro-sidebar {
    /* transform: translateX(0); */
  }


}



@media (max-width: 1440px) and (min-width: 1280px) {
  
  #wrapper .container-xl {
    max-width: 1280px;
    padding: 0 1.5rem;
  }

  .slider-area .slider-content h2 {
    font-size: 40px;
}

  .slider-area .carousel {
    padding: calc(14vh + 84px) 0 14vh;
}





}




@media (max-width: 1279px) and (min-width: 1201px) {
  

  .slider-area .slider-content h2 {
    font-size: 40px;
}

  .slider-area .carousel {
    padding: calc(14vh + 84px) 0 14vh;
}





}



@media (max-width: 1200px) and (min-width: 992px) {


  .slider-area .slider-content h2 {
      font-size: 40px;
  }
  .slider-area .carousel {
      padding: calc(14vh + 84px) 0 14vh;
  }
  .menu-area .btn1 {
    line-height: 40px;
    height: 40px;
    border-radius: 40px;
    padding: 0 22px;
    font-size: 14px;
    text-align: center;
    min-width: fit-content;
}
header .menu-area .login-btn {
  margin: 0 10px 0 5px;
}
header .menu-area nav ul li {
  padding: 0;
}

header .menu-area {
  padding-top: 5px;
  display: flex;
  justify-content: flex-end;
}
header .menu-area .login-btn {
  display: inline-flex;
}
header .menu-area {
    padding-top: 5px;
    display: flex;
    justify-content: flex-end;
}
.nav-link {
  display: block;
  padding: .6rem 0.8rem;
}
}








@media (max-width: 767px) and (min-width: 320px) {

  .buttons {
    justify-content: center;
}

.slider-area .slider-content p {
    color: #ffffff;
    font-weight: 400;
    font-size: 15px;
    margin: 20px 0 15px;
}

html, body {
  font-size: 15px;
}

.ss_menu_item ul {
  background: #333;
  padding: 15px;
}
.ss_menu_item ul li a {
  padding: 5px;
  font-size: 15px;
  font-weight: 400;
}








  .qr_code {
    flex-direction: column;
  }
  .qr_code .qr_content {
    padding-left: 0;
    width: 100%;
    margin-top: 1rem;
  }
.card {
  margin-bottom: 3rem;
}
#wrapper {
	min-height: calc(100vh - 7px);
	margin-top: 78px;
}



.btn-icon {
	padding: 10px 0;
	height: 40px !important;
	width: 40px !important;
	min-width: 40px !important;
	margin: 0 15px 0 0;
}
.btn-icon svg {
	width: 20px;
	height: 20px;
}

.profile_bar span{
  display: none;
}

.menu-area-profile {
	display: flex;
	justify-content: flex-end;
}
.profile_bar {
	padding: 0;
}
.header_2 .logo-area img {
	width: auto;
	height: 35px;
	max-width: fit-content;
}

.menu-area.menu-area-profile {
	padding: 0;
}

.profile_bar .img-account-profile.rounded-circle {
	width: 40px;
	height: 40px;
}


.copy_code {
	text-align: right;
	margin-top: 1rem;
}

}

